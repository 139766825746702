// NOTE: Poniższe polyfille MUSZĄ być na samym początku pliku, nie wolno przenosić!
import 'core-js/features/object';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

/** @jsxImportSource @welldone-software/why-did-you-render */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';

import store from 'store/store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';

if (process.env.NODE_ENV === 'development') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        trackAllPureComponents: false
    });
}

const { REACT_APP_GIT_VERSION } = process.env;

const render = Component =>
    ReactDOM.render(
        <Provider store={store}>
            <React.StrictMode>
                <Component />
            </React.StrictMode>
        </Provider>,
        document.getElementById('root')
    );

render(App);

if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
        module.hot.accept('./App', () => {
            // eslint-disable-next-line global-require
            const NextApp = require('./App').default;
            render(NextApp);
        });
    }
} else {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            new Sentry.Integrations.GlobalHandlers({
                onerror: true,
                onunhandledrejection: true
            })
        ],
        release: REACT_APP_GIT_VERSION
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
