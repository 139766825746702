export const iconTypes = {
    light: 'fal',
    regular: 'far',
    duoTone: 'fad',
    brands: 'fab',
    solid: 'fas'
};

const icons = {
    file: 'fa-file-alt',
    thumbUp: 'fa-thumbs-up',
    download: 'fa-arrow-to-bottom',
    check: 'fa-check',
    info: 'fa-info',
    truckLoading: 'fa-truck-loading',
    peopleLoading: 'fa-people-carry',
    chevronDown: 'fa-chevron-down',
    arrowDown: 'fa-arrow-down',
    exclamation: 'fa-exclamation',
    bell: 'fa-bell',
    cross: 'fa-times',
    refresh: 'fa-sync-alt',
    eye: 'fa-eye',
    eyeSlash: 'fa-eye-slash',
    send: 'fa-paper-plane',
    trash: 'fa-trash'
};

export default icons;
