import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/Icon';
import icons from 'utils/icons';

import styles from './Option.module.scss';

const propTypes = {
    active: PropTypes.bool,
    value: PropTypes.any,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

const defaultProps = {
    value: undefined,
    active: false
};

const Option = ({ value, label, onClick, active }) => {
    const handleClick = () => {
        onClick({ label, value });
    };

    return (
        <div onClick={handleClick} className={styles.wrapper}>
            {label}
            {active && (
                <Icon icon={icons.check} className={styles.icon} />
            )}
        </div>
    );
};

Option.propTypes = propTypes;
Option.defaultProps = defaultProps;

export default memo(Option);
