import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import PageLoader from 'common/PageLoader';
import { toastTypes } from 'utils/consts';
import { displayToast } from 'Toasts/actions';
import Invoice from './Invoice';
import InvoiceSrv from './InvoiceSrv';
import { useTranslation } from 'react-i18next';

const propTypes = {};

const defaultProps = {};

function InvoiceContainer() {
    const dispatch = useDispatch();
    const { invoiceId } = useParams();
    const [details, setDetails] = useState();
    const [hasFetchingError, setHasFetchingError] = useState(false);
    const { t } = useTranslation();

    const getInvoiceData = () =>
        InvoiceSrv.get(invoiceId)
            .then(res => setDetails(res))
            .catch(() => setHasFetchingError(true));

    useEffect(() => {
        getInvoiceData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceId]);

    const confirmReceiptGoods = () =>
        InvoiceSrv.confirmReceiptGoods(details.confirmationToken).then(
            async () => {
                await getInvoiceData();
            }
        );

    const updateDataFromWapro = () =>
        InvoiceSrv.updateDataFromWapro(invoiceId).then(async () => {
            dispatch(displayToast(t('UpdateDataSuccess'), toastTypes.success));
            await getInvoiceData();
        });

    const requestNotificationSend = notificationType =>
        InvoiceSrv.requestNotificationSend(invoiceId, notificationType).then(
            async () => {
                dispatch(
                    displayToast(t('NotificationCreated'), toastTypes.success)
                );
                await getInvoiceData();
            }
        );

    const toggleAlerts = async () => {
        if (details.alertsDisabled)
            await InvoiceSrv.enableAlerts(invoiceId);
        else
            await InvoiceSrv.disableAlerts(invoiceId);
        dispatch(displayToast(t('ConfigurationChanged'), toastTypes.success));
        await getInvoiceData();
    }

    const removeInvoice = async () => {
        await InvoiceSrv.remove(invoiceId);
        dispatch(displayToast("Faktura usunięta", toastTypes.success));
        await getInvoiceData();
    }

    return (
        <PageLoader
            isLoading={isEmpty(details)}
            hasLoadingError={hasFetchingError}
        >
            <Invoice
                details={details}
                confirmReceiptGoods={confirmReceiptGoods}
                updateDataFromWapro={updateDataFromWapro}
                requestNotificationSend={requestNotificationSend}
                toggleAlerts={toggleAlerts}
                removeInvoice={removeInvoice}
            />
        </PageLoader>
    );
}

InvoiceContainer.propTypes = propTypes;
InvoiceContainer.defaultProps = defaultProps;

export default memo(InvoiceContainer);
