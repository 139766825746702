import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@progress/kendo-react-dialogs';

const propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  onCloseClick: PropTypes.func,
  children: PropTypes.any,
};

const defaultProps = {
  title: 'Potwierdź',
  body: 'Jesteś pewien?',
  onCloseClick: undefined,
  children: undefined,
};

function FoxDialog({ title, body, onCloseClick, children }) {
  return (
    <React.Fragment>
      <Dialog title={title} onClose={onCloseClick}>
        <p>{body}</p>
        {children}
      </Dialog>
    </React.Fragment>
  );
}

FoxDialog.propTypes = propTypes;
FoxDialog.defaultProps = defaultProps;

export default FoxDialog;
