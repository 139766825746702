import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";

function configureStore() {
    // const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
    const composeEnhancers = composeWithDevTools({});

    const store = createStore(
        rootReducer,
        {},
        composeEnhancers(applyMiddleware(thunk))
    );

    if (process.env.NODE_ENV !== "production") {
        if (module.hot) {
            module.hot.accept("./rootReducer", () => {
                store.replaceReducer(rootReducer);
            });
        }
    }

    return store;
}

const store = configureStore();

export default store;
