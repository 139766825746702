import React, { memo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Icon from 'common/Icon';
import icons from 'utils/icons';
import { useOutsideClick } from 'utils/customHooks';

import Options from './Options';
import styles from './Select.module.scss';

const propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.exact({
            value: PropTypes.any,
            label: PropTypes.string.isRequired
        })
    ),
    value: PropTypes.any.isRequired,
    // TODO Vorbert -> zrobić komponent dropdowna
    // TODO Vorbert -> po zrobieniu komponentu, od-ifować 'label' oraz 'showActiveOption'
    label: PropTypes.string,
    onChange: PropTypes.func,
    showActiveOption: PropTypes.bool
};

const defaultProps = {
    options: [],
    onChange: () => {},
    showActiveOption: true
};

const Select = ({ options, value, onChange, label, showActiveOption }) => {
    const haveOptions = !isEmpty(options);
    const wrapperRef = useRef(null);
    const findOption = optionValue =>
        options.find(option => option.value === optionValue) || {};
    const [showOptions, setShowOptions] = useState(false);
    const [activeOption, setActiveOption] = useState(() => findOption(value));

    useEffect(() => {
        const currentValue = findOption(value);
        setActiveOption(currentValue);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleOnChange = option => {
        if (!showActiveOption || option.value !== activeOption.value) {
            onChange(option);
            setActiveOption(option);
        }

        setShowOptions(false);
    };

    const toggleOptionsVisibility = () => {
        setShowOptions(!showOptions);
    };

    const handleOutsideClick = () => {
        setShowOptions(false);
    };

    useOutsideClick(wrapperRef, handleOutsideClick);

    return (
        <div className={styles.wrapper} ref={wrapperRef}>
            <span
                className={styles.labelWrapper}
                onClick={toggleOptionsVisibility}
            >
                {label || activeOption.label}
                {haveOptions && (
                    <Icon icon={icons.chevronDown} className={styles.icon} />
                )}
            </span>
            {haveOptions && (
                <Options
                    onChange={handleOnChange}
                    options={options}
                    isVisible={showOptions}
                    activeOption={activeOption}
                    showActiveOption={showActiveOption}
                />
            )}
        </div>
    );
};

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default memo(Select);
