import styled, { css } from 'styled-components';

const StyledWrapper = styled.section`
    display: flex;
    padding: 1.25rem 0;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    ${({ verticallyCenter }) => verticallyCenter && css`
        margin: auto;
    `}
`;

export default StyledWrapper;
