import { Button } from '@progress/kendo-react-buttons';
import styled from 'styled-components';

const FoxButton = styled(Button)`
    & + & {
        margin-left: 0.75rem;
    }
`;

FoxButton.defaultProps = {
    type: 'submit'
};

export default FoxButton;
