import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { splitByNewLine } from 'utils/utils';
import { colorTypes } from 'utils/consts';
import Text from 'common/Text';

const propTypes = {
    placeAddress: PropTypes.string.isRequired
};

const defaultProps = {};

const SplitedPlaceInformations = ({ placeAddress, ...others }) => {
    const [accentedInformation, ...otherInformations] = splitByNewLine(
        placeAddress
    );

    return (
        <>
            <Text colorType={colorTypes.accent} {...others}>
                {accentedInformation}
            </Text>
            {otherInformations.map((information, informationIndex) => (
                <Text key={informationIndex}>{information}</Text>
            ))}
        </>
    );
};

SplitedPlaceInformations.propTypes = propTypes;
SplitedPlaceInformations.defaultProps = defaultProps;

export default memo(SplitedPlaceInformations);
