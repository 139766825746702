export function getHeaderOptimalWidth(headerWidth) {
    const minColWidth = 140;
    // Dodatkowa szerokość na ikonki z lewej strony nagłówka grida, czy też trzy kropki, który znajdują się po jego prawej stronie
    const additionallWidth = 40;
    const defaultIconWidth = 83;

    if (!headerWidth) return defaultIconWidth;

    return headerWidth + additionallWidth > minColWidth
        ? headerWidth + additionallWidth
        : minColWidth;
}

export function setColumnWidth(wrappedGridRef) {
    // wrappedGridRef &&
    //   (() => {
    //     const colGroups = [...wrappedGridRef.querySelectorAll('colgroup')];
    //     const widthOfTheHeaders = this.getColumnHeaderHeight();
    //     const headerContainerWidth = parseFloat(
    //       window
    //         .getComputedStyle(document.querySelector('.k-grid > .k-grid-header'))
    //         .getPropertyValue('width'),
    //     );
    //     widthOfTheHeaders &&
    //       // Kalkulowanie szerości tekstów w każdej kolumnie grida oraz sprawdzanie czy suma tych szerokości mieści się w szerokości grida.
    //       colGroups.forEach(colGroup => {
    //         const rowCells = [...colGroup.querySelectorAll('col')];
    //         const allColumnWidth = rowCells
    //           .map((rowCell, rowCellIndex) =>
    //             getHeaderOptimalWidth(widthOfTheHeaders[rowCellIndex]),
    //           )
    //           .reduce((prev, next) => prev + next);
    //         // Dostosowywanie szerokości kolumn, w razie gdy ich tekst nie mieści się w kontenerze grida
    //         allColumnWidth > headerContainerWidth &&
    //           (() => {
    //             rowCells.forEach((rowCell, rowCellIndex) => {
    //               rowCell.setAttribute(
    //                 'width',
    //                 `${getHeaderOptimalWidth(widthOfTheHeaders[rowCellIndex])}px`,
    //               );
    //             });
    //           })();
    //       });
    //   })();
}

export function getColumnHeaderHeight() {
    const { wrappedGridRef } = this;
    const isIcon = undefined;
    const headerLinks = [
        ...wrappedGridRef.querySelectorAll(
            ".k-grid-header a, .k-grid-header .icon-cell"
        ),
    ];
    const widthOfTheHeaders = headerLinks.map((headerLink) => {
        const spanWrapper = document.createElement("span");
        const textNode = [...headerLink.childNodes].filter(
            (childNode) => childNode.nodeType === 3
        )[0];

        if (!textNode) return isIcon;

        // Wstawianie textNode do spana, by odczytać szerokość tekstu
        headerLink.insertBefore(spanWrapper, textNode);
        spanWrapper.appendChild(textNode);
        const textNodeWidth = spanWrapper.scrollWidth;

        // Nie usuwam tutaj powyżej tworzonego spana, żeby móc dodać feature (używając jego znaczników) trzykropka dla  tekstu, który nie mieści się w kolumnie grida
        return textNodeWidth;
    });

    return widthOfTheHeaders;
}

export function getFilterResetVerticalOffset() {
    const getElementTopOffset = (elementQuery) => {
        const element = document.querySelector(elementQuery);
        const elementTopOffset = element
            ? element.getBoundingClientRect().top
            : 0;

        return elementTopOffset;
    };

    const filterSecionTopOffset = getElementTopOffset(".k-filtercell");
    const gridContainerTopOffset = getElementTopOffset(".grid_container");

    const filterOffsetFromGrid = filterSecionTopOffset - gridContainerTopOffset;
    this.setState({ filterButtonOffset: filterOffsetFromGrid });
}

export function scrollPageToGrid() {
    const gridActionButton = document.querySelector(
        ".inner-content-wrapper > a"
    );

    const scrollAnchorElement =
        gridActionButton ||
        document.querySelector(".inner-content-wrapper > div");

    scrollAnchorElement &&
        scrollAnchorElement.scrollIntoView({ behavior: "smooth" });
}

export function addMissingTitleAttributes() {
    const gridColumnHeaderLinks = document.querySelectorAll(
        ".k-grid-header table th .k-link"
    );

    gridColumnHeaderLinks.forEach((link) =>
        link.setAttribute("title", link.innerText)
    );
}
