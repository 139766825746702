import toastAcionTypes from './actionTypes';

export const displayToast = (message, type, duration = 20000) => dispatch => {
    dispatch({
        type: toastAcionTypes.TOAST_DISPLAY,
        message,
        toastType: type,
        duration
    });
};

export const closeToast = id => dispatch => {
    dispatch({
        type: toastAcionTypes.TOAST_HIDE,
        id
    });
};
