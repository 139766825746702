import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { colorTypes, notificationType } from 'utils/consts';
import Section from 'common/Section';
import icons from 'utils/icons';
import List from 'common/List';

import StyledButton from './StyledButton';
import StyledName from './StyledName';
import StyledNameSufix from './StyledNameSufix';
import StyledListItem from './StyledListItem';

const propTypes = {
    requestNotificationSend: PropTypes.func.isRequired
};

const defaultProps = {};

const availableNotifications = [
    {
        label: 'Potwierdzenie otrzymania towaru',
        type: notificationType.invoiceConfirmation,
        buttonColorType: colorTypes.accent
    },
    {
        label: 'Ponaglenie',
        type: notificationType.invoiceConfirmationReminder,
        buttonColorType: colorTypes.info
    }
];

const InvoiceNotifications = ({ requestNotificationSend }) => {
    const { t } = useTranslation();

    const handleClick = notificationType => {
        requestNotificationSend(notificationType);
    };

    return (
        <Section
            icon={icons.bell}
            title={`${t('Invoice.Notifications.SectionTitle')}:`}
        >
            <List>
                {availableNotifications.map(notification => (
                    <StyledListItem withLine={false} centerOnMobile>
                        <StyledName>
                            <StyledNameSufix>{'Nazwa: '}</StyledNameSufix>
                            {notification.label}
                        </StyledName>
                        <StyledButton
                            icon={icons.send}
                            onClick={() => handleClick(notification.type)}
                            colorType={notification.buttonColorType}
                        >
                            Wyślij powiadomienie
                        </StyledButton>
                    </StyledListItem>
                ))}
            </List>
        </Section>
    );
};

InvoiceNotifications.propTypes = propTypes;
InvoiceNotifications.defaultProps = defaultProps;

export default memo(InvoiceNotifications);
