import axios from 'axios';
import { getApiBaseAddress } from './apiBaseAddress';
import store from 'store/store';
import { displayToast } from 'Toasts/actions';
import { toastTypes } from 'utils/consts';
import i18n from 'i18next';

function doNothing(conf) {
    return conf;
}

const instance = axios.create({
    baseURL: getApiBaseAddress()
});

function handle500(response) {
    const errorCode = response?.data?.exceptionGuid;

    var errors =
        response?.data?.errors.map(x =>
            i18n.t(`Error.${x.code}`, 'Unhandled error occured')
        ) || [];

    errors.unshift(errorCode);

    const errorMessage = `${errors.join('\n')}`;
    store.dispatch(displayToast(errorMessage, toastTypes.error));
}

function handle400(response) {
    var errors =
        response?.data?.errors.map(x => i18n.t(`Error.${x.code}`)) || [];

    const errorMessage = `${errors.join('\n')}`;

    store.dispatch(displayToast(errorMessage, toastTypes.warning));
}

instance.interceptors.response.use(doNothing, error => {
    const response = error.response || error;

    if (response.status === 400) {
        handle400(response);
    } else if (response.status >= 500 && response.status < 600) {
        handle500(response);
    } else {
        store.dispatch(displayToast(response.message, toastTypes.error));
    }

    return Promise.reject(error);
});

export default instance;
