import styled from 'styled-components';
import RootSectionWrapper from './RootSectionWrapper';

// TODO Vorbert -> znaleźć alternatywę dla flexa i kolumn
const PageContent = styled(RootSectionWrapper)`
    display: flex;
    flex-direction: column;
    flex: 1;

    /* NOTE: IE fix */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex: 0 0 auto;
    }
`;

export default PageContent;
