import React, { memo, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import NotificationTemplateEdit from './NotificationTemplateEdit';
import NotificationTemplateSrv from './NotificationTemplateSrv';
import urls from 'common/urls';

const propTypes = {};

const defaultProps = {};

function NotificationTemplateEditContainer() {
    const { notificationTemplateId } = useParams();
    const [initialValues, setInitialValues] = useState();
    const { push } = useHistory();

    useEffect(() => {
        async function get() {
            const res = await NotificationTemplateSrv.get(
                notificationTemplateId
            );
            setInitialValues(res);
        }
        get().catch(console.error);
    }, [notificationTemplateId]);

    const submit = model => NotificationTemplateSrv.update(model);

    return (
        <NotificationTemplateEdit
            initialValues={initialValues}
            onSubmit={submit}
            onSubmitSuccess={() =>
                push(urls.administrationNotificationTemplates)
            }
        />
    );
}

NotificationTemplateEditContainer.propTypes = propTypes;
NotificationTemplateEditContainer.defaultProps = defaultProps;

export default memo(NotificationTemplateEditContainer);
