import styled, { css } from 'styled-components';
import { isUsingInternetExplorer } from 'utils/consts';

const propTypes = {};

const defaultProps = {};

const OffsetWrapper = styled.section`
    /* TODO Vorbert -> Rozpowszechnić użycie tej zmiennej */
    --offset: ${({ theme }) => theme.defaultVerticalMargin};

    &:not(:first-child) {
        /* NOTE Vorbert -> został tu użyty padding dlatego że z marginem może wyniknąć problem spowodowany łączeniem się marginesów */
        padding-top: var(--offset);

        @media screen and (max-width: 476px) {
            padding-top: calc(var(--offset) * 1.5);
        }
    }

    ${isUsingInternetExplorer &&
    css`
        padding-top: 2rem;
    `}
`;

OffsetWrapper.propTypes = propTypes;
OffsetWrapper.defaultProps = defaultProps;
export default OffsetWrapper;
