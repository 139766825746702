import React, { memo } from 'react';
import PropTypes from 'prop-types';
import StyledSvg from './StyledSvg';

// TODO Vorbert -> Zastanowić się nad implementacją spinnera z różnymi rozmiarami typu "large/small"
// TODO Vorbert -> dodać możliwość przekazania wielkości bordera (w tym przypadku jest to "stroke")
const propTypes = {
    isLoading: PropTypes.bool,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const defaultProps = {
    isLoading: true,
    size: '4rem'
};

const Loader = ({ isLoading, size, ...props }) => {
    return isLoading && <StyledSvg width={size} height={size} {...props} />;
};

Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;

export default memo(Loader);
