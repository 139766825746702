import axios from 'config/axios';
import { saveAs } from 'file-saver';
import { getApiBaseAddress } from '../../config/apiBaseAddress';

const prefix = 'administration/invoice';

const returnData = res => res.data;

export default class InvoiceSrv {
    static getForGrid(dataState) {
        return axios.post(`${prefix}/getForGrid`, dataState).then(returnData);
    }

    static get(invoiceId) {
        return axios.get(`${prefix}/${invoiceId}`).then(returnData);
    }

    static confirmReceiptGoods(confirmationToken) {
        return axios
            .post(`${prefix}/${confirmationToken}/confirmReceiptGoods`)
            .then(returnData);
    }

    static requestNotificationSend(invoiceId, notificationType) {
        return axios.put(
            `${prefix}/requestNotification/${invoiceId}/${notificationType}`
        );
    }

    static downloadInvoice(confirmationId) {
        saveAs(
            `${getApiBaseAddress()}${prefix}/${confirmationId}/downloadInvoice`
        );
    }

    static downloadInvoiceSilently(invoiceId) {
        saveAs(
            `${getApiBaseAddress()}${prefix}/downloadInvoice/silently/${invoiceId}`
        );
    }

    static updateDataFromWapro(invoiceId) {
        return axios.post(`${prefix}/updateDataFromWapro/${invoiceId}`);
    }

    static enableAlerts(invoiceId) {
        return axios.put(`${prefix}/${invoiceId}/enableAlerts`);
    }

    static disableAlerts(invoiceId) {
        return axios.put(`${prefix}/${invoiceId}/disableAlerts`);
    }

    static remove(invoiceId) {
        return axios.put(`${prefix}/${invoiceId}/remove`);
    }
}
