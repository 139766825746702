import gridNames from "common/gridNames";
import { gridStoreResults } from "FoxReact/FoxGrid/actions";
import InvoiceSrv from "./InvoiceSrv";

export function storeResults(results) {
    return gridStoreResults(gridNames.administrationInvoices)(results);
}

export function getForGrid(dataState) {
    return function (dispatch) {
        return InvoiceSrv.getForGrid(dataState).then((results) =>
            dispatch(storeResults(results))
        );
    };
}
