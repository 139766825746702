import React, { memo } from 'react';

import StyledBackgroundImage from './StyledBackgroundImage';

const propTypes = {};

const defaultProps = {};

const LandingPage = () => {
    return <StyledBackgroundImage />;
};

LandingPage.propTypes = propTypes;
LandingPage.defaultProps = defaultProps;

export default memo(LandingPage);
