import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { iconTypes } from 'utils/icons';
import sharedPropTypes from 'utils/sharedPropTypes';
import styled from 'styled-components';
import cx from 'classnames';

const propTypes = {
    icon: sharedPropTypes.icon.isRequired,
    type: sharedPropTypes.iconType,
    className: PropTypes.string
};

const defaultProps = {
    type: 'regular',
    className: undefined
};

const StyledIcon = styled.i``;

const Icon = ({ type, icon, className, ...others }) => {
    const iconClassName = cx(`${iconTypes[type]} ${icon}`, className);

    return <StyledIcon className={iconClassName} {...others} />;
};

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default memo(Icon);
