import React, { memo } from 'react';
import PropTypes from 'prop-types';
import List from 'common/List';

import TimelineItem from './TimelineItem';

const propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            message: PropTypes.string,
            date: PropTypes.string,
            trackingInfo: PropTypes.shape({
                ipAddress: PropTypes.string,
                userAgent: PropTypes.string
            }),
            code: PropTypes.string.isRequired
        })
    ),
    title: PropTypes.string
};

const defaultProps = {
    items: [],
    title: PropTypes.string
};

const Timeline = ({ items, title }) => {
    return (
        <List title={title}>
            {items.map((item, itemIndex) => (
                <TimelineItem
                    additionalData={item.additionalData}
                    message={item.message}
                    date={item.date}
                    trackingInfo={item.trackingInfo}
                    key={itemIndex}
                    isLastItem={itemIndex === items.length - 1}
                    code={item.code}
                />
            ))}
        </List>
    );
};

Timeline.propTypes = propTypes;
Timeline.defaultProps = defaultProps;

export default memo(Timeline);
