import React, { memo } from 'react';
import NotificationTemplates from './NotificationTemplates';
import { useDispatch } from 'react-redux';
import { getForGrid } from './action';

const propTypes = {};

const defaultProps = {};

function NotificationTemplatesContainer() {
    const dispatch = useDispatch();
    const onLoadData = dataState => dispatch(getForGrid(dataState));

    return <NotificationTemplates onLoadData={onLoadData} />;
}

NotificationTemplatesContainer.propTypes = propTypes;
NotificationTemplatesContainer.defaultProps = defaultProps;

export default memo(NotificationTemplatesContainer);
