import React from 'react';
import PropTypes from 'prop-types';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import FoxDialog from './FoxDialog';
import FoxButton from './FoxButton';

const propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  noLabel: PropTypes.string,
  yesLabel: PropTypes.string,
  onCloseClick: PropTypes.func,
  onNoClick: PropTypes.func,
  onYesClick: PropTypes.func,
};

const defaultProps = {
  title: 'Potwierdź',
  body: 'Jesteś pewien?',
  noLabel: 'Nie',
  yesLabel: 'Tak',
  onCloseClick: undefined,
  onNoClick: undefined,
  onYesClick: undefined,
};

function FoxConfirmationDialog({
  title,
  body,
  noLabel,
  yesLabel,
  onCloseClick,
  onNoClick,
  onYesClick,
}) {
  return (
    <FoxDialog title={title} body={body} onCloseClick={onCloseClick}>
      <DialogActionsBar>
        <FoxButton type="button" onClick={onNoClick}>
          {noLabel}
        </FoxButton>
        <FoxButton primary type="button" onClick={onYesClick}>
          {yesLabel}
        </FoxButton>
      </DialogActionsBar>
    </FoxDialog>
  );
}

FoxConfirmationDialog.propTypes = propTypes;
FoxConfirmationDialog.defaultProps = defaultProps;

export default FoxConfirmationDialog;
