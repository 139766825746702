import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from './useAuth';

const propTypes = {};

const defaultProps = {};

function Login(props) {
    const { login } = useAuth();
    const { t } = useTranslation();

    useEffect(() => {
        login();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <span>{t('Login.Description')}</span>;
}

Login.propTypes = propTypes;
Login.defaultProps = defaultProps;

export default memo(Login);
