import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import LoadingPageError from 'common/LoadingPageError';
import StyledContentWrapper from './StyledContentWrapper';
import ContentLoader from 'common/ContentLoader';

const propTypes = {
    isLoading: PropTypes.bool,
    hasLoadingError: PropTypes.bool,
    children: PropTypes.node.isRequired
};

const defaultProps = {
    isLoading: false,
    hasLoadingError: false
};

const PageLoader = ({ isLoading, hasLoadingError, children }) => {
    const displayLoader = isLoading && !hasLoadingError;
    const contentToDisplay = hasLoadingError ? <LoadingPageError /> : children;
    const { t } = useTranslation();

    return (
        <ContentLoader
            isLoading={displayLoader}
            verticallyCenter
            label={t('PageLoader.Description')}
            renderContent={content => (
                <StyledContentWrapper>{content}</StyledContentWrapper>
            )}
        >
            {contentToDisplay}
        </ContentLoader>
    );
};

PageLoader.propTypes = propTypes;
PageLoader.defaultProps = defaultProps;

export default memo(PageLoader);
