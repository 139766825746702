export function gridStoreResults(prefix) {
  return results => ({
    type: `${prefix}_STORE_RESULTS`,
    results,
  });
}

export function gridOnGroupChange(prefix) {
  return group => ({
    type: `${prefix}_STORE_GROUP`,
    group,
  });
}

export function gridOnDataStateChange(prefix) {
  return dataState => ({
    type: `${prefix}_STORE_DATASTATE`,
    dataState,
  });
}

export function gridOnSetDefaultSort(prefix) {
  return defaultSort => ({
    type: `${prefix}_SET_DEFAULT_SORT`,
    defaultSort,
  });
}

export function clearFilter(prefix) {
  return {
    type: `${prefix}_CLEAR_FILTERS`,
  };
}

export function setNewOrder(prefix) {
  return payload => ({
    type: `${prefix}_SET_NEW_ORDER`,
    payload,
  });
}

export function setColumnsSubmit(prefix) {
  return payload => ({
    type: `${prefix}_SET_COLUMNS_SUBMIT`,
    payload,
  });
}

export function setColumnSizes(prefix) {
  return payload => ({
    type: `${prefix}_SET_COLUMNS_SIZES`,
    payload,
  });
}
