import styled from 'styled-components';
import wordImage from 'assets/images/wordMap.png';

const BackgroundImage = styled.img`
    display: none;

    @media screen and (min-width: 768px) {
        position: absolute;
        display: block;
        top: 0;
        left: 50%;
        width: min(990px, 90vw);
        transform: translateX(-50%);
        object-fit: contain;
        z-index: -1;
    }
`;

BackgroundImage.defaultProps = {
    src: wordImage
};

export default BackgroundImage;
