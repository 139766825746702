import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEqual } from 'lodash';

import Option from './Option';
import styles from './Options.module.scss';

const propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.exact({
            value: PropTypes.any,
            label: PropTypes.string.isRequired
        })
    ).isRequired,
    activeOption: PropTypes.exact({
        value: PropTypes.any,
        label: PropTypes.string.isRequired
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    isVisible: PropTypes.bool,
    showActiveOption: PropTypes.bool
};

const defaultProps = {
    isVisible: false
};

const Options = ({
    options,
    onChange,
    isVisible,
    activeOption,
    showActiveOption
}) => {
    const wrapperClassName = classNames(styles.optionsWrapper, {
        [styles.visibleWrapper]: isVisible
    });

    return (
        <div className={wrapperClassName}>
            {options.map(option => (
                <Option
                    key={option.label}
                    onClick={onChange}
                    value={option.value}
                    label={option.label}
                    active={showActiveOption && isEqual(option, activeOption)}
                />
            ))}
        </div>
    );
};

Options.propTypes = propTypes;
Options.defaultProps = defaultProps;

export default memo(Options);
