import React, { memo } from 'react';
import createFoxGridContainer from 'FoxReact/FoxGrid/createFoxGridContainer';
import gridNames from 'common/gridNames';
import FoxGridColumn from 'FoxReact/FoxGrid/FoxGridColumn';
import FoxGridCell from 'FoxReact/FoxGrid/FoxGridCell';
import { Link } from 'react-router-dom';
import urls from 'common/urls';
import Button from 'common/Button';
import icons from 'utils/icons';
import InvoiceSrv from './InvoiceSrv';
import { colorTypes } from 'utils/consts';

const propTypes = {};

const defaultProps = {};

const FoxGridContainer = createFoxGridContainer(
    gridNames.administrationInvoices
);

function Invoices({ onLoadData }) {
    return (
        <>
            <FoxGridContainer onLoadData={onLoadData} defaultSortField="issueDate" defaultSortDir="desc">
                <FoxGridColumn
                    field="waproId"
                    title="Id faktury"
                    cell={({ dataItem, ...others }) => (
                        <FoxGridCell dataItem={dataItem} {...others}>
                            <Link to={urls.administrationInvoice(dataItem.id)}>
                                {dataItem.waproId}
                            </Link>
                        </FoxGridCell>
                    )}
                />
                <FoxGridColumn field="number" title="Nr f-vat" />
                <FoxGridColumn // TODO ppolak: format on dates
                    field="issueDate"
                    title="Data wystawienia"
                    filter="date"
                />
                <FoxGridColumn field="status" title="Status" />
                <FoxGridColumn field="email" title="Email" />
                <FoxGridColumn
                    field="country"
                    title="Kod kraju"
                    width="100px"
                />
                <FoxGridColumn field="employeeEmail" title="Email pracownika" />
                <FoxGridColumn field="cmr" title="Cmr" />
                <FoxGridColumn
                    field="sendNotificationAfterDays"
                    title="Ilość dni do wysyłki"
                />
                <FoxGridColumn
                    sortable={false}
                    filterable={false}
                    field="actions"
                    title="Akcje"
                    width="160px"
                    cell={({ dataItem, ...others }) => (
                        <FoxGridCell dataItem={dataItem} {...others}>
                            <Button
                                icon={icons.download}
                                onClick={() =>
                                    InvoiceSrv.downloadInvoiceSilently(
                                        dataItem.id
                                    )
                                }
                                colorType={colorTypes.accent}
                                disabled={!dataItem.pdfExists}
                                title={
                                    !dataItem.pdfExists &&
                                    'PDF nie został jeszcze wygenerowany'
                                }
                            >
                                Pobierz fakturę
                            </Button>
                        </FoxGridCell>
                    )}
                />
            </FoxGridContainer>
        </>
    );
}

Invoices.propTypes = propTypes;
Invoices.defaultProps = defaultProps;

export default memo(Invoices);
