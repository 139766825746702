import wrapFormField from '../../FoxReact/wrapFormField';
import FoxInputText from '../../FoxReact/FoxInputText';
import React, { memo, useState } from 'react';
import FoxButton from '../../FoxReact/FoxButton';
import { useStore } from 'react-redux';
import { getFormValues } from 'redux-form';
import NotificationTemplateSrv from './NotificationTemplateSrv';

const FoxInputTextWithLabel = wrapFormField(FoxInputText);

function PreviewGenerator({index}) {
    const [invoiceId, setInvoiceId] = useState("");

    const [previewData, setPreviewData] = useState(null);

    const store = useStore();
    const showPreview = async () => {
        const formValues = getFormValues("notifcationTemplateEdit")(store.getState());
        const currentDefinition = formValues.definitions[index];

        const data = await NotificationTemplateSrv.generatePreview(invoiceId, currentDefinition);
        setPreviewData(data);
    };


    return (<>
        <FoxInputTextWithLabel value={invoiceId} onChange={e => setInvoiceId(e.target.value)}
                               label='Podaj guid faktury aby wyświetlić podgląd' />
        <FoxButton disabled={!invoiceId} onClick={showPreview}>Generuj podgląd</FoxButton>
        {previewData && (
            <>
                <div>
                    DW: {previewData.cc}
                </div>
                <div>
                    UDW: {previewData.bcc}
                </div>
                <div>
                    Odpowiedz do: {previewData.replyTo}
                </div>
                <div>
                    Temat: <div dangerouslySetInnerHTML={{__html: previewData.subject}}/>
                </div>
                <div>
                    Email: <div dangerouslySetInnerHTML={{__html: previewData.content}}/>
                </div>
            </>
        )}
    </>);
}

export default memo(PreviewGenerator);
