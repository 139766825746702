import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import icons from 'utils/icons';
import { useToggleValue } from 'utils/customHooks';

import Link from 'common/Link';

import StyledRawContent from './StyledRawContent';
import TimelineItemDescription from './TimelineItemDescription';

const propTypes = {
    content: PropTypes.string
};

const defaultProps = {
    content: undefined
};

const NotificationContentDisplay = ({ content }) => {
    const { t } = useTranslation();

    const [
        showContent,
        { linkIcon, linkLabel },
        toggleShowContent
    ] = useToggleValue(
        false,
        {
            linkIcon: icons.eyeSlash,
            linkLabel: 'Admin.Invoice.Timeline.Notification.HideContent'
        },
        {
            linkIcon: icons.eye,
            linkLabel: 'Admin.Invoice.Timeline.Notification.ShowContent'
        }
    );

    const handleToggleShowContent = () => {
        toggleShowContent();
    };

    return (
        content && (
            <>
                {showContent && <StyledRawContent content={content} />}
                <TimelineItemDescription withoutTransparency>
                    <Link
                        to="#"
                        icon={linkIcon}
                        onClick={handleToggleShowContent}
                    >
                        {t(linkLabel)}
                    </Link>
                </TimelineItemDescription>
            </>
        )
    );
};

NotificationContentDisplay.propTypes = propTypes;
NotificationContentDisplay.defaultProps = defaultProps;

export default memo(NotificationContentDisplay);
