import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeToast } from './actions';
import styles from './Toasts.module.scss';
import Toast from './Toast';

const propTypes = {};

const defaultProps = {};

const Toasts = () => {
    const dispatch = useDispatch();
    const handleCloseToast = id => () => dispatch(closeToast(id));
    const notifications = useSelector(state => {
        return state.toasts.notifications.sort(
            (current, next) => current.creationTime - next.creationTime
        );
    });

    return (
        <div className={styles.toast_container}>
            {notifications.reverse().map(notification => (
                <Toast
                    key={notification.id}
                    onToastClose={handleCloseToast(notification.id)}
                    type={notification.type}
                    closeTime={notification.duration}
                >
                    {notification.message}
                </Toast>
            ))}
        </div>
    );
};

Toasts.propTypes = propTypes;
Toasts.defaultProps = defaultProps;

export default Toasts;
