import { connect } from 'react-redux';
import {
  gridOnDataStateChange,
  gridOnGroupChange,
  gridOnSetDefaultSort,
  clearFilter,
  setNewOrder,
  setColumnsSubmit,
  setColumnSizes,
} from './actions';

import FoxGrid from './FoxGrid';

function createFoxGridContainer(prefix) {
  const mapStateToProps = (state, ownProps) => ({
    results: state[prefix].results,
    dataState: state[prefix].dataState,
    columnsInfo: state[prefix].columnsInfo,
    ...ownProps,
  });

  const mapDispatchToProps = dispatch => {
    return {
      onDataStateChange: dataState =>
        dispatch(gridOnDataStateChange(prefix)(dataState)),
      onGroupChange: group => dispatch(gridOnGroupChange(prefix)(group)),
      onSetDefaultSort: defaultSort =>
        dispatch(gridOnSetDefaultSort(prefix)(defaultSort)),
      onResetFilters: () => dispatch(clearFilter(prefix)),
      onColumnReorder: newOrder => dispatch(setNewOrder(prefix)(newOrder)),
      onColumnsSubmit: columnsSubmit =>
        dispatch(setColumnsSubmit(prefix)(columnsSubmit)),
      onColumnResize: newSizes => dispatch(setColumnSizes(prefix)(newSizes)),
    };
  };

  const foxGridContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FoxGrid);

  return foxGridContainer;
}

export default createFoxGridContainer;
