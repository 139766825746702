import React, { memo } from 'react';
import PropTypes from 'prop-types';
import withColors from 'common/hoc/withColors';

import StyledWrapper from './StyledWrapper';

const propTypes = {
    children: PropTypes.node.isRequired
};

const defaultProps = {};

// NOTE: Można tutaj przekazywać propsy ze styled components, czyli np.: as="h1", żeby wyrenderować Header jako tag h1
const Header = ({ type, children, ...others }) => {
    return <StyledWrapper {...others}>{children}</StyledWrapper>;
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default memo(withColors(Header));
