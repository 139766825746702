import styled, { css } from 'styled-components';
import Row from 'common/Row';
import { isUsingInternetExplorer } from 'utils/consts';

const StyledWrapper = styled(Row)`
    & + & {
        margin-top: var(--vertical-padding);

        ${isUsingInternetExplorer &&
        css`
            margin-top: 1rem;
        `}
    }

    @media screen and (max-width: 760px) {
        text-align: center;
    }
`;

export default StyledWrapper;
