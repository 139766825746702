import React, { memo } from 'react';
import logo from 'assets/images/logo.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { supportedLangs } from 'utils/consts';
import { getCurrentLanguage } from 'utils';
import urls from 'common/urls';
import RootSectionWrapper from 'common/RootSectionWrapper';
import Select from 'common/Select';

import StyledActionItem from './StyledActionItem';
import StyledActionSection from './StyledActionSection';
import AdministrationNavigation from './AdministrationNavigation';
import styles from './Navbar.module.scss';

const propTypes = {};

const defaultProps = {};

const languages = supportedLangs.map(lang => ({
    value: lang,
    label: lang.toUpperCase()
}));

// TODO Vorbert -> przebudować nawigacje
const Navbar = () => {
    const { i18n: translator, t } = useTranslation();
    const currentLanguage = getCurrentLanguage(translator.language);
    const handleLanguageChange = ({ value }) => {
        translator.changeLanguage(value);
    };

    return (
        <RootSectionWrapper as="nav" className={styles.wrapper}>
            <Link to={urls.landingPage} title={t('GoToTheHomePage')}>
                <img src={logo} className={styles.logo} alt="Logo" />
            </Link>
            <StyledActionSection>
                <AdministrationNavigation />
                <StyledActionItem>
                    <Select
                        value={currentLanguage}
                        options={languages}
                        onChange={handleLanguageChange}
                    />
                </StyledActionItem>
            </StyledActionSection>
        </RootSectionWrapper>
    );
};

Navbar.propTypes = propTypes;
Navbar.defaultProps = defaultProps;

export default memo(Navbar);
