import React from 'react';
import FormField from './FormField';

const wrapFormField = (WrappedComponent, wrappedProps) => props => {
    return (
        // eslint-disable-next-line react/prop-types
        <FormField
            {...props}
            {...wrappedProps}
            required={props.required && !props.disabled}
        >
            <WrappedComponent {...props} label={undefined} />
        </FormField>
    );
};

export default wrapFormField;
