import styled, { css } from 'styled-components';
import { Link as LinkBase } from 'react-router-dom';

const Link = styled(LinkBase)`
    font-size: 1em;
    color: ${({ theme }) => theme.accent};
    font-weight: 500;

    ${({ display }) =>
        display &&
        css`
            display: ${display};
        `};
`;

export default Link;
