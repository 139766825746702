import { useDispatch } from 'react-redux';
import { displayToast } from 'Toasts/actions';
import { toastTypes } from 'utils/consts';

/**
 * Returns {Object} {showSucess: fn(message: string), showNotification: fn(message: string)})
 */
export default function useToast() {
    const dispatch = useDispatch();

    const showSuccess = message => {
        dispatch(displayToast(message, toastTypes.success));
    };

    const showNotification = message => {
        dispatch(displayToast(message, toastTypes.notification));
    };

    return { showSuccess, showNotification };
}
