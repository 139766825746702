import AuthSrv from './AuthSrv';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import urls from 'common/urls';

/**
 * @returns { logged: boolean, login: f() }
 */
const useAuth = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const isLogged = useSelector(state => state.auth.logged);

    const redirectToOriginalPage = () => {
        const { from } = location.state || { from: { pathname: '/' } };
        history.replace(from);
    };

    const login = async () => {
        try {
            const ret = await AuthSrv.login();

            if (ret.status === 200) {
                dispatch({
                    type: 'AUTH_LOGIN'
                });

                redirectToOriginalPage();
                return;
            }

            history.push(urls.page401);
        } catch (error) {
            history.push(urls.page401);
        }
    };

    return { isLogged, login };
};

export default useAuth;
