import axios from 'config/axios';

const prefix = 'auth';

export default class AuthSrv {
    static login() {
        //trigger login on nginx http
        return axios.post(`${prefix}/login`);
    }
}
