import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isNumber, getLoadingZeros } from 'utils/utils';
import Icon from 'common/Icon';
import sharedPropTypes from 'utils/sharedPropTypes';

import StyledNumericIcon from './StyledNumericIcon';
import StyledThinNumber from './StyledThinNumber';
import StyledWrapper from './StyledWrapper';

const propTypes = {
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    iconType: sharedPropTypes.iconType
};

const defaultProps = {
    iconType: undefined
};

const SectionIcon = ({ icon, iconType, ...others }) => {
    const isNumericIcon = isNumber(icon);

    return (
        <StyledWrapper
            {...others}
            data-section-number={isNumericIcon ? icon : undefined}
        >
            {isNumericIcon ? (
                <StyledNumericIcon>
                    <StyledThinNumber>{getLoadingZeros(icon)}</StyledThinNumber>
                    {icon}
                </StyledNumericIcon>
            ) : (
                <Icon icon={icon} type={iconType} />
            )}
        </StyledWrapper>
    );
};

SectionIcon.propTypes = propTypes;
SectionIcon.defaultProps = defaultProps;

export default memo(SectionIcon);
