import React, { memo } from 'react';
import PropTypes from 'prop-types';
import icons from 'utils/icons';
import { useTranslation } from 'react-i18next';

import LoaderLabel from 'common/ContentLoader/StyledLabel';
import StyledIcon from './StyledIcon';
import StyledWrapper from 'common/ContentLoader/StyledWrapper';

const propTypes = {
    label: PropTypes.string
};

const defaultProps = {
    label: undefined
};

const LoadingPageError = ({ label, isLoading, ...others }) => {
    const { t } = useTranslation();

    return (
        <StyledWrapper {...others} verticallyCenter>
            <StyledIcon icon={icons.cross} />
            <LoaderLabel>
                {label || t('LoadingPageError.DefaultDescription')}
            </LoaderLabel>
        </StyledWrapper>
    );
};

LoadingPageError.propTypes = propTypes;
LoadingPageError.defaultProps = defaultProps;

export default memo(LoadingPageError);
