import styled, { css } from 'styled-components';

const FlexContainer = styled.div`
    display: flex;

    ${({ alignItems }) =>
        alignItems &&
        css`
            align-items: ${alignItems};
        `}

    ${({ alignContent }) =>
        alignContent &&
        css`
            align-content: ${alignContent};
        `}

    ${({ justifyContent }) =>
        justifyContent &&
        css`
            justify-content: ${justifyContent};
        `}

    ${({ justifyItems }) =>
        justifyItems &&
        css`
            justify-items: ${justifyItems};
        `}
`;

export default FlexContainer;
