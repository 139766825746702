import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
    colorTypes,
    dateAndHourFormat,
    invoiceHighlightedLogTypes
} from 'utils/consts';
import { formatAsTitleText } from 'utils';
import ListItem from 'common/ListItem';

import TimelineDescription from './TimelineItemDescription';
import styles from './TimelineItem.module.scss';
import TrackingInfo from './TrackingInfo';
import NotificationData from './NotificationData';

const propTypes = {
    message: PropTypes.string,
    date: PropTypes.string,
    trackingInfo: PropTypes.shape({
        ipAddress: PropTypes.string,
        userAgent: PropTypes.string
    }),
    isLastItem: PropTypes.bool,
    code: PropTypes.string.isRequired
};

const defaultProps = {
    trackingInfo: {},
    isLastItem: false
};

const TimelineItem = ({
    message,
    date,
    trackingInfo,
    isLastItem,
    code,
    additionalData
}) => {
    const { t } = useTranslation();
    const formatedDate = useMemo(() => moment(date).format(dateAndHourFormat), [
        date
    ]);

    const colorType = useMemo(
        () =>
            invoiceHighlightedLogTypes.includes(code)
                ? colorTypes.accent
                : undefined,
        [code]
    );

    return (
        <ListItem withLine={!isLastItem} colorType={colorType}>
            <div className={styles.header}>{message || '-'}</div>
            {date && (
                <TimelineDescription>
                    {`${formatAsTitleText(t('Date'))} ${formatedDate}`}
                </TimelineDescription>
            )}
            <TrackingInfo trackingInfo={trackingInfo} />
            {additionalData && (
                <NotificationData additionalData={additionalData} />
            )}
        </ListItem>
    );
};

TimelineItem.propTypes = propTypes;
TimelineItem.defaultProps = defaultProps;

export default memo(TimelineItem);
