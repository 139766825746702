import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import StyledInput from './StyledInput';

const propTypes = {
    className: PropTypes.string,
    invalid: PropTypes.bool
};

const defaultProps = {};

const FoxInputText = ({ className, invalid, ...others }) => {
    const inputClassName = cn(className, 'k-textbox', {
        'k-state-invalid': invalid
    });

    return <StyledInput className={inputClassName} {...others} />;
};

FoxInputText.propTypes = propTypes;
FoxInputText.defaultProps = defaultProps;

export default memo(FoxInputText);
