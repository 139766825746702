import styled from 'styled-components';
import Loader from 'common/Loader';

// TODO Vorbert -> dorobić animacje

const StyledLoader = styled(Loader)`
    margin-right: ${2 / 3}em;
`;

export default StyledLoader;
