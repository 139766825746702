const initialState = {
    logged: false
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'AUTH_LOGIN':
            return {
                ...state,
                logged: true
            };
        default:
            return state;
    }
};

export default authReducer;
