import styled, { css } from 'styled-components';

const StyledActionItem = styled.span`
    & + & {
        margin-left: 1.25rem;
    }

    ${({ hideOnMobile }) =>
        hideOnMobile &&
        css`
            @media screen and (max-width: 476px) {
                display: none;
            }
        `}
`;

export default StyledActionItem;
