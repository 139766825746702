import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import icons from 'utils/icons';
import Table from 'common/Table';
import Button from 'common/Button';
import List from 'common/List';
import OffsetWrapper from 'common/OffsetWrapper';
import Row from 'common/Row';
import Col from 'common/Col';
import Timeline from 'common/Timeline';
import Header from 'common/Header';
import Section from 'common/Section';
import SectionStep from 'common/SectionStep';
import Text from 'common/Text';
import SplitedPlaceInformations from 'common/SplitedPlaceInformations';
import BackgroundImage from 'common/BackgroundImage';
import { colorTypes } from 'utils/consts';
import { getFormatedDate, isDefaultLang, isPlaceStringProvided } from 'utils/utils';
import processType from 'consts/processTypes';
import { mapInvoiceDetailsPositions } from 'utils/mappers';
import InvoiceLabels from 'common/InvoiceLabels';

import styles from './Invoice.module.scss';
import InvoiceSrv from './InvoiceSrv';
import InvoiceNotifications from './InvoiceNotifications';
import AdministrationActions from './AdministrationActions';

const propTypes = {
    details: PropTypes.object,
    confirmReceiptGoods: PropTypes.func.isRequired,
    updateDataFromWapro: PropTypes.func.isRequired,
    requestNotificationSend: PropTypes.func.isRequired,
    toggleAlerts: PropTypes.func,
    removeInvoice: PropTypes.func,
};

const defaultProps = {};

function Invoice({
     details,
     confirmReceiptGoods,
     updateDataFromWapro,
     requestNotificationSend,
     toggleAlerts,
     removeInvoice
 }) {
    const { t, i18n: translator } = useTranslation();
    const isDefaultLangaugeSelected = isDefaultLang(translator.language);
    const productItems = useMemo(
        () => mapInvoiceDetailsPositions(details.positions),
        [details.positions]
    );

    const dataIsConfirmed = !!details.confirmationDate;
    const invoiceWasDownloaded = !!details.downloadDate;
    const showSections = details.processStarted;
    const [
        downloadedInvoiceWithoutReload,
        setDownloadedInvoiceWithoutReload
    ] = useState(invoiceWasDownloaded);

    const currentProcess = {
        isCorrection: details.processType === processType.correction,
        isSell: details.processType === processType.sell
    };

    const confirmationSection = {
        displayButton: currentProcess.isSell && !dataIsConfirmed && !details.removed,
        displayDate: currentProcess.isSell && dataIsConfirmed,
        displaySection: !!details.cmrName
    };

    const invoiceSection = {
        displayDate: invoiceWasDownloaded,
        canDownloadInvoice: dataIsConfirmed || currentProcess.isCorrection
    };

    const placeSection = {
        displayUnloading: isPlaceStringProvided(details.unloadingPlace),
        displayLoading: isPlaceStringProvided(details.loadingPlace)
    };

    placeSection.displaySection =
        placeSection.displayUnloading || placeSection.displayLoading;

    const dataConfirmColor = dataIsConfirmed
        ? colorTypes.success
        : colorTypes.accent;

    const invoiceDownloadColor =
        invoiceWasDownloaded || downloadedInvoiceWithoutReload
            ? colorTypes.success
            : colorTypes.accent;

    const confirmationDateInfo =
        confirmationSection.displayDate &&
        `${t('Confirmed')}: ${getFormatedDate(details.confirmationDate)}`;

    const invoiceDownloadDateInfo =
        invoiceSection.displayDate &&
        `${t('Invoice.DownloadedInvoice')}: ${getFormatedDate(
            details.downloadDate
        )}`;

    const handleDownloadInvoice = () => {
        if (!downloadedInvoiceWithoutReload) {
            setDownloadedInvoiceWithoutReload(true);
        }

        InvoiceSrv.downloadInvoice(details.confirmationToken);
    };

    return (
        <>
            {showSections && (
                <OffsetWrapper>
                    <Section moreVerticalOffset>
                        <Row
                            className={styles.stepHeaderRow}
                            alignItems='center'
                        >
                            <Header as='h4' className={styles.stepHeader}>
                                {t('Invoice.Greeting')}
                            </Header>
                            <InvoiceLabels
                                isReminder={details.reminder}
                                isCritical={details.critical}
                            />
                        </Row>
                        {confirmationSection.displaySection && (
                            <SectionStep
                                title={t('Invoice.ConfirmationStep.Title')}
                                colorType={dataConfirmColor}
                                step={1}
                                showButton={confirmationSection.displayButton}
                            >
                                {t('Invoice.ConfirmationStep.Description')}
                                {confirmationSection.displayDate && (
                                    <div className={styles.confirmationText}>
                                        {confirmationDateInfo}
                                    </div>
                                )}
                            </SectionStep>
                        )}
                        <SectionStep
                            title={t('Invoice.DownloadStep.Title')}
                            colorType={invoiceDownloadColor}
                            disabled={!invoiceSection.canDownloadInvoice}
                            showButton={invoiceSection.canDownloadInvoice}
                            step={2}
                        >
                            {t('Invoice.DownloadStep.Description')}
                            {invoiceSection.displayDate && (
                                <div className={styles.confirmationText}>
                                    {invoiceDownloadDateInfo}
                                </div>
                            )}
                        </SectionStep>
                    </Section>
                </OffsetWrapper>
            )}

            {placeSection.displaySection && (
                <OffsetWrapper>
                    <Row breakOnTablet>
                        {placeSection.displayLoading && (
                            <Col>
                                <Section
                                    title={t('LoadingPlace')}
                                    icon={icons.truckLoading}
                                >
                                    <SplitedPlaceInformations
                                        placeAddress={details.loadingPlace}
                                    />
                                </Section>
                            </Col>
                        )}
                        {placeSection.displayUnloading && (
                            <Col>
                                <Section
                                    title={t('UnloadingPlace')}
                                    icon={icons.peopleLoading}
                                    iconType='solid'
                                >
                                    <SplitedPlaceInformations
                                        placeAddress={details.unloadingPlace}
                                    />
                                </Section>
                            </Col>
                        )}
                    </Row>
                </OffsetWrapper>
            )}

            {!isEmpty(details.positions) && (
                <OffsetWrapper>
                    <Table
                        columns={[
                            {
                                name: 'index',
                                label: t('OrdinaryNumber'),
                                width: isDefaultLangaugeSelected
                                    ? '60px'
                                    : '70px'
                            },
                            {
                                name: 'name',
                                label: t('Invoice.ProductsTable.Name')
                            },
                            {
                                name: 'unit',
                                label: t('Invoice.ProductsTable.Unit'),
                                width: isDefaultLangaugeSelected
                                    ? '115px'
                                    : '75px'
                            },
                            {
                                name: 'quantity',
                                label: t('Invoice.ProductsTable.Quantity'),
                                width: isDefaultLangaugeSelected
                                    ? '80px'
                                    : '115px',
                                numberValues: true
                            }
                        ]}
                        items={productItems}
                    />
                </OffsetWrapper>
            )}

            {!isEmpty(details.resolvedOrders) && (
                <OffsetWrapper>
                    <Section icon={icons.file}>
                        <List
                            title={t('Invoice.SettledOrdersNumbers')}
                            items={details.resolvedOrders}
                            withOverflow
                        />
                    </Section>
                </OffsetWrapper>
            )}

            <OffsetWrapper>
                {showSections && (
                    <Row breakOnTablet>
                        {confirmationSection.displaySection && (
                            <Col>
                                <Section
                                    icon={1}
                                    title={`${t('CmrNumber')} ${
                                        details.cmrName
                                    }`}
                                    colorType={dataConfirmColor}
                                >
                                    <div>
                                        {t(
                                            'Invoice.ConfirmationStep.Explanation'
                                        )}
                                    </div>
                                    {confirmationSection.displayDate && (
                                        <div
                                            className={styles.confirmationText}
                                        >
                                            {confirmationDateInfo}
                                        </div>
                                    )}
                                    {confirmationSection.displayButton && (
                                        <Button
                                            onClick={confirmReceiptGoods}
                                            icon={icons.thumbUp}
                                            colorType={dataConfirmColor}
                                        >
                                            {t('Invoice.ConfirmDownload')}
                                        </Button>
                                    )}
                                </Section>
                            </Col>
                        )}
                        <Col>
                            <Section
                                icon={2}
                                colorType={invoiceDownloadColor}
                                disabled={!invoiceSection.canDownloadInvoice}
                                title={`${t('Invoice')} ${details.invoiceName}`}
                            >
                                <div>
                                    {t('Invoice.DownloadStep.Explanation')}
                                </div>
                                {invoiceSection.displayDate && (
                                    <div className={styles.confirmationText}>
                                        {invoiceDownloadDateInfo}
                                    </div>
                                )}
                                <Button
                                    disabled={
                                        !invoiceSection.canDownloadInvoice
                                    }
                                    icon={icons.download}
                                    onClick={handleDownloadInvoice}
                                >
                                    {t('DownloadInvoice')}
                                </Button>
                            </Section>
                        </Col>
                    </Row>
                )}
                {!showSections && (
                    <Button onClick={updateDataFromWapro} icon={icons.refresh}>
                        {t('Admin.Invoice.UpdateWaproData')}
                    </Button>
                )}
            </OffsetWrapper>

            {!details.removed && <OffsetWrapper>
                <InvoiceNotifications
                    requestNotificationSend={requestNotificationSend}
                />
            </OffsetWrapper>}

            {!isEmpty(details.events) && (
                <OffsetWrapper>
                    <Section icon={icons.info} iconType='solid'>
                        <Timeline
                            items={details.events}
                            title={t('Admin.Invoice.Timeline.Title')}
                        />
                    </Section>
                </OffsetWrapper>
            )}
            {!details.removed && <AdministrationActions details={details} toggleAlerts={toggleAlerts} removeInvoice={removeInvoice} />}

            <OffsetWrapper className={styles.signatureWrapper}>
                <Text>{t('Invoice.VidokThanks')}</Text>
                <Text fontWeight='bold'>{t('Invoice.VidokTeam')}</Text>
            </OffsetWrapper>

            <BackgroundImage />
        </>
    );
}

Invoice.propTypes = propTypes;
Invoice.defaultProps = defaultProps;

export default memo(Invoice);
