import styled, { keyframes } from 'styled-components';
import Icon from 'common/Icon';

const rotateAnimation = keyframes`
    from {
        opacity: .15;
        transform: translateY(0.35em) rotate(0deg) scale(0.35);
    }

    to {
        opacity: 1;
        transform: translateY(0) rotate(360deg) scale(1) ;
    }
`;

const StyledIcon = styled(Icon)`
    font-size: 6rem;
    /* NOTE Vorbert -> wysokość jest ustawiona na inną, niż font size, bo fontawesome dodał do niej sztuczną, pustą wysokość */
    height: 4.75rem;
    color: ${({ theme }) => theme.accent};
    animation: 1000ms ease ${rotateAnimation};
`;

export default StyledIcon;
