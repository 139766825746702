import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from 'common/Icon';
import {
    getElementHeight,
    getElementPropertyValueString
} from '../utils/domMethods';
import styles from './Toast.module.scss';
import CloseButton from './CloseButton';
import { getToastProperties } from './toastUtils';

const propTypes = {
    onToastClose: PropTypes.func.isRequired,
    children: PropTypes.string.isRequired,
    type: PropTypes.string,
    closeTime: PropTypes.number
};

const defaultProps = {
    type: '',
    closeTime: 20000
};

const Toast = ({ onToastClose, children, type, closeTime }) => {
    const [toastProperties] = useState(getToastProperties(type));
    const [toastIsTouched, setToastIsTouched] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastStyles, setToastStyles] = useState({});
    const animationDuration = 500;
    const toastRef = useRef();
    const toastClassName = cx(
        styles.toast,
        styles[`toast_${toastProperties.color}`],
        {
            [styles.visible]: showToast
        }
    );

    useEffect(() => {
        setTimeout(() => {
            setShowToast(true);
        }, 50);
    }, []);

    useEffect(() => {
        const { current: toast } = toastRef;

        setToastStyles(prevStyles => ({
            ...prevStyles,
            height: `${getElementHeight(toast)}px`,
            padding: getElementPropertyValueString(toast, 'padding')
        }));
    }, [toastRef]);

    const onClose = () => {
        setToastStyles(prevStyles => ({
            ...prevStyles,
            height: 0,
            padding: 0
        }));
        setShowToast(false);
        setTimeout(() => {
            onToastClose();
        }, animationDuration);
    };

    const handleMouseEnter = () => {
        setToastIsTouched(true);
    };

    const handleMouseLeave = () => {
        setToastIsTouched(false);
    };

    return (
        <div
            className={toastClassName}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={toastStyles}
            ref={toastRef}
        >
            <div className={styles.row}>
                <div className={styles.column}>
                    <div className={styles.icon_circle}>
                        <Icon
                            icon={toastProperties.icon}
                            className={styles.icon}
                            type="solid"
                        />
                    </div>
                </div>
                <div className={styles.text_container}>{children}</div>
            </div>
            <CloseButton
                {...{ closeTime, onClose, toastIsTouched }}
                counterDelay={animationDuration}
            />
        </div>
    );
};

Toast.propTypes = propTypes;
Toast.defaultProps = defaultProps;

export default Toast;
