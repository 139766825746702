import React, { memo } from 'react';
import PropTypes from 'prop-types';

import StyledWrapper from './StyledWrapper';

const propTypes = {
    content: PropTypes.string
};

const defaultProps = {};

const RawContent = ({ content, ...passedProps }) => {
    return (
        <StyledWrapper
            {...passedProps}
            dangerouslySetInnerHTML={{
                __html: content
            }}
        />
    );
};

RawContent.propTypes = propTypes;
RawContent.defaultProps = defaultProps;

export default memo(RawContent);
