import styled, { css } from 'styled-components';

const StyledInput = styled.input`
    ${({ fullWidth }) =>
        fullWidth &&
        css`
            width: 100%;
        `}
`;

StyledInput.defaultProps = {
    type: 'text',
    label: '',
    autoFocus: false,
    required: false,
    disabled: false,
    value: undefined,
    fullWidth: false
};

export default StyledInput;
