import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { parseToPx } from 'utils/stylingUtils';
import FlexContainer from 'common/FlexContainer';

const propTypes = {
    breakOnMobile: PropTypes.bool
};

const defaultProps = {
    breakOnMobile: true
};

const Row = styled(FlexContainer)`
    flex-direction: row;
    flex-wrap: wrap;

    /* NOTE: IE fix */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex: 0 0 auto;
    }

    ${({ breakOnMobile }) =>
        breakOnMobile &&
        css`
            @media screen and (max-width: 760px) {
                flex-direction: column;
            }
        `}

    ${({ breakOnTablet }) =>
        breakOnTablet &&
        css`
            @media screen and (max-width: 1024px) {
                flex-direction: column;
            }
        `}

    ${({ breakOn }) =>
        breakOn &&
        css`
            @media screen and (max-width: ${parseToPx(breakOn)}) {
                flex-direction: column;
            }
        `}
`;

Row.propTypes = propTypes;
Row.defaultProps = defaultProps;

export default Row;
