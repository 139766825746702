import styled, { css } from 'styled-components';
import withColors from 'common/hoc/withColors';

const Text = styled.div`
    color: var(--color, ${({ theme }) => theme.light});
    word-break: break-word;

    ${({ fontWeight }) =>
        fontWeight &&
        css`
            font-weight: ${fontWeight};
        `}

    ${({ display }) =>
        display &&
        css`
            display: ${display};
        `};

    ${({ inheritColor }) =>
        inheritColor &&
        css`
            color: inherit;
        `};
`;

export default withColors(Text);
