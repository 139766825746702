import React, { memo } from 'react';
import PropTypes from 'prop-types';
import withColors from 'common/hoc/withColors';
import ListItemDecorations from './ListItemDecorations';
import ListItemWrapper from './ListItemWrapper';

import styles from './ListItem.module.scss';

const propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node
    ]),
    withLine: PropTypes.bool,
    centerOnMobile: PropTypes.bool
};

const defaultProps = {
    withLine: true
};

// TODO Vorbert -> dorobić propa z tytułem
const ListItem = ({ children, withLine, centerOnMobile, ...others }) => {
    return (
        <ListItemWrapper centerOnMobile={centerOnMobile} {...others}>
            <ListItemDecorations
                withLine={withLine}
                hideOnMobile={centerOnMobile}
            />
            <div className={styles.itemWrapper}>
                <span className={styles.content}>{children}</span>
            </div>
        </ListItemWrapper>
    );
};

ListItem.propTypes = propTypes;
ListItem.defaultProps = defaultProps;

export default memo(withColors(ListItem));
