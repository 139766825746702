import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useToast from 'Toasts/useToast';
import copyToClipboard from 'utils/copyToClipboard';
import FoxButton from 'FoxReact/FoxButton';
import FormField from 'FoxReact/FormField';

const propTypes = {
    placeholders: PropTypes.array.isRequired
};

const defaultProps = {};

function AvailablePlaceholders({ placeholders }) {
    const { showNotification } = useToast();

    const onCopyToClipboard = placeholder => () => {
        const placeholderValue = `{{${placeholder.code}}}`;
        copyToClipboard(placeholderValue);
        let message = `Skopiowano "${placeholderValue}" do schowka, teraz możesz go wkleić w do treści szablonu`;
        if (placeholder.description) {
            message += `, ${placeholder.description}`;
        }
        showNotification(message);
    };

    return (
        <FormField label="Dostępne szablony">
            <div>
                {placeholders.map(placeholder => (
                    <FoxButton
                        key={placeholder.code}
                        type="button"
                        onClick={onCopyToClipboard(placeholder)}
                    >
                        {placeholder.code}
                    </FoxButton>
                ))}
            </div>
        </FormField>
    );
}

AvailablePlaceholders.propTypes = propTypes;
AvailablePlaceholders.defaultProps = defaultProps;

export default memo(AvailablePlaceholders);
