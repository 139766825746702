import moment from 'moment';
import {
    supportedLangs,
    defaultLang,
    availableCssSizeUnits,
    dateAndHourFormat
} from './consts';

export const getCurrentLanguage = currentLanguage => {
    return supportedLangs.includes(currentLanguage)
        ? currentLanguage
        : defaultLang;
};

export const isDefaultLang = language => {
    return language === defaultLang;
};

// NOTE: tylko dla dodatnich liczb
export const getLeadingZerosQuantity = (number = 0, outputNumberLength = 2) => {
    const leadingZerosQuantity = outputNumberLength - `${number}`.length;
    return leadingZerosQuantity;
};

export const getLoadingZeros = (number = 0, outputNumberLength = 2) => {
    const leadingZerosQuantity = getLeadingZerosQuantity(
        number,
        outputNumberLength
    );
    return '0'.repeat(leadingZerosQuantity);
};

export const getNumberWithLeadingZeros = (
    number,
    zerosQuantity = getLeadingZerosQuantity(number)
) => {
    return `${'0'.repeat(zerosQuantity)}${number}`;
};

export const elementHasBeenScrolled = element =>
    element && element.scrollTop > 0;

export const splitByNewLine = (text = '') => text.split('\n');

export const hasValidCssUnit = (text = '') => {
    const validCssUnit = availableCssSizeUnits.find(sizeUnit =>
        text.endsWith(sizeUnit)
    );
    return !!validCssUnit;
};

export const toCorrectCssUnit = (value = '') => {
    const isValidCssValue = hasValidCssUnit(value);
    return isValidCssValue ? value : `${value}px`;
};

export const isNumber = value => !isNaN(value);

export const isString = value => typeof value === 'string';

export const scrollToElement = element =>
    element &&
    element.scrollIntoView({
        behavior: 'smooth'
    });

export const getFormatedDate = (date, format = dateAndHourFormat) =>
    moment(date).format(format);

// TODO Vorbert -> zastanowić się nad rozpropagowaniem użycia tej metody
export const formatAsTitleText = text => `${text}:`;

export const elementIsVisibleInViewport = element => {
    if (!element) return false;

    const { bottom: elementBottom } = element.getBoundingClientRect();
    const elementIsVisible = elementBottom <= window.innerHeight;
    return elementIsVisible;
};

export const setCookie = (name, value = '', expiringDays = 365) => {
    const expiringDate = moment().add(expiringDays, 'days');
    document.cookie = `${name}=${value}; expires=${expiringDate.toDate()}; path=/`;
};

export const getCookie = (cookieName = '') => {
    const cookies = document.cookie.split('; ').map(cookie => {
        const [name, value] = cookie.split('=');
        return {
            name,
            value
        };
    });

    const searchedCookie = cookies.find(({ name }) => name === cookieName);
    return searchedCookie?.value;
};

export const createHtmlElement = (elementName, parameters = {}) => {
    const element = document.createElement(elementName);
    Object.keys(parameters).forEach(parameterName => {
        const parameterValue = parameters[parameterName];
        element[parameterName] = parameterValue;
    });

    return element;
};

export const isPlaceStringProvided = (placeString) => placeString && !!placeString.replaceAll('\n', '');
