import React, { memo, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from './Footer';
import Navbar from './Navbar';
import PageContent from './PageContent';
import styles from './Layout.module.scss';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';

const Cookies = lazy(() => import('./Cookies'));

const propTypes = {};
const defaultProps = {};

function Layout({ children }) {
    const { ready } = useTranslation();
    const location = useLocation();
    const isAdministration = location.pathname.startsWith("/administration");

    const sectionClassName = cn(styles.wrapper, !isAdministration ? styles.wrapperMaxWidth : undefined);
    return (
        <>
            {ready && (
                <>
                    <section className={sectionClassName}>
                        <Navbar />
                        <PageContent as="main">{children}</PageContent>
                    </section>
                    <Footer />
                    <Suspense fallback={<div />}>
                        <Cookies />
                    </Suspense>
                </>
            )}
        </>
    );
}

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default memo(Layout);
