import React, { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import PageLoader from 'common/PageLoader';

import InvoiceConfirmation from './InvoiceConfirmation';
import InvoiceConfirmationSrv from './InvoiceConfirmationSrv';

const propTypes = {};

const defaultProps = {};

function InvoiceConfirmationContainer() {
    const { confirmationId } = useParams();
    const [details, setDetails] = useState();
    const [hasFetchingError, setHasFetchingError] = useState(false);

    useEffect(() => {
        const get = () =>
            InvoiceConfirmationSrv.get(confirmationId)
                .then(res => setDetails(res.data))
                .catch(() => setHasFetchingError(true));

        get();
    }, [confirmationId]);

    const confirmReceiptGoods = () =>
        InvoiceConfirmationSrv.confirmReceiptGoods(confirmationId).then(() => {
            InvoiceConfirmationSrv.get(confirmationId).then(res =>
                setDetails(res.data)
            );
        });

    return (
        <PageLoader
            isLoading={isEmpty(details)}
            hasLoadingError={hasFetchingError}
        >
            <InvoiceConfirmation
                details={details}
                confirmReceiptGoods={confirmReceiptGoods}
                confirmationId={confirmationId}
            />
        </PageLoader>
    );
}

InvoiceConfirmationContainer.propTypes = propTypes;
InvoiceConfirmationContainer.defaultProps = defaultProps;

export default memo(InvoiceConfirmationContainer);
