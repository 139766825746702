const urls = {
    invoiceConfirmation: confirmationId =>
        `/invoice/confirmation/${confirmationId}`,
    administrationInvoices: '/administration/invoices',
    administrationInvoice: invoiceId => `/administration/invoice/${invoiceId}`,
    administrationNotificationTemplates:
        '/administration/notificationTemplates',
    administrationNotificationTemplate: templateId =>
        `/administration/notificationTemplate/${templateId}`,
    login: '/login',
    page401: '/401',
    landingPage: '/'
};

export default urls;
