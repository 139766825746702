import styled from 'styled-components';
import ListItem from 'common/ListItem';
import { pxToRem } from 'utils/stylingUtils';

const StyledListItem = styled(ListItem)`
    @media screen and (max-width: 476px) {
        &,
        & + & {
            margin-top: ${pxToRem(12)};
        }
    }
`;

export default StyledListItem;
