import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useHasBeenSeen } from 'utils/customHooks';

import styles from './ListItemDecorations.module.scss';

const propTypes = {
    withLine: PropTypes.bool,
    hideOnMobile: PropTypes.bool
};

const defaultProps = {
    withLine: true,
    hideOnMobile: false
};

const ListItemDecorations = ({ withLine, hideOnMobile }) => {
    const lineRef = useRef(null);
    const animateLine = useHasBeenSeen(withLine ? lineRef : undefined);
    const lineClassName = cx(styles.line, {
        [styles.lineVisible]: withLine && animateLine
    });

    const wrapperClassName = cx(styles.wrapper, {
        [styles.hiddenOnMobile]: hideOnMobile
    });

    return (
        <div className={wrapperClassName} ref={lineRef}>
            <span className={styles.bullet} />
            {withLine && <span className={lineClassName} ref={lineRef} />}
        </div>
    );
};

ListItemDecorations.propTypes = propTypes;
ListItemDecorations.defaultProps = defaultProps;

export default memo(ListItemDecorations);
