import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuth from 'Auth/useAuth';
import Select from 'common/Select';
import urls from 'common/urls';

import StyledActionItem from './StyledActionItem';

const getAdminUrls = translate => [
    {
        value: urls.administrationInvoices,
        label: translate('Navbar.Links.InvoicesList')
    },
    {
        value: urls.administrationNotificationTemplates,
        label: translate('Navbar.Links.NotificationsList')
    }
];

const propTypes = {};

const defaultProps = {};

const AdministrationNavigation = () => {
    const { t } = useTranslation();
    const { isLogged } = useAuth();
    const { push: redirect } = useHistory();
    const adminUrls = getAdminUrls(t);
    const handleLinkRedirect = ({ value }) => {
        redirect(value);
    };

    return (
        isLogged && (
            <StyledActionItem hideOnMobile>
                <Select
                    options={adminUrls}
                    onChange={handleLinkRedirect}
                    label={t('Navbar.Actions.AdministratorLists')}
                    showActiveOption={false}
                />
            </StyledActionItem>
        )
    );
};

AdministrationNavigation.propTypes = propTypes;
AdministrationNavigation.defaultProps = defaultProps;

export default memo(AdministrationNavigation);
