import React from 'react';
import FoxGridColumn from './FoxGridColumn';

export function applayColumnInfo(cols) {
  const { columnsInfo } = this.props;
  if (!columnsInfo || columnsInfo.length === 0) {
    return cols;
  }

  const newColumns = [...cols].sort((prev, next) => {
    const prevEq = columnsInfo.find(x => x.field === prev.props.field);
    const nextEq = columnsInfo.find(x => x.field === next.props.field);
    const prevEqOrderIndex = prevEq ? prevEq.orderIndex : 0;
    const nextEqOrderIndex = nextEq ? nextEq.orderIndex : 0;

    return prevEqOrderIndex > nextEqOrderIndex ? 1 : -1;
  });

  for (let i = 0; i < columnsInfo.length; i++) {
    const element = columnsInfo[i];
    const updateIndex = newColumns.findIndex(
      x => x.props.field === element.field,
    );
    if (updateIndex !== -1) {
      newColumns[updateIndex] = (
        <FoxGridColumn
          {...newColumns[updateIndex].props}
          hidden={element.hidden}
          width={element.width}
        />
      );
    }
  }
  return newColumns;
}

export function columnReorder(event) {
  const { onColumnReorder } = this.props;

  const newOrder = event.columns.map(x => ({
    field: x.field,
    orderIndex: x.orderIndex,
  }));

  onColumnReorder(newOrder);
}

export function columResize(event) {
  if (!event.end) {
    return;
  }

  const { onColumnResize } = this.props;

  const newSizes = event.columns.map(x => ({
    field: x.field,
    width: x.width,
  }));

  onColumnResize(newSizes);
}

export function columnsSubmit(columnsState) {
  const { onColumnsSubmit } = this.props;

  this.setState({ columns: columnsState });
  const hiddenColumnInfo = columnsState.map(x => ({
    field: x.props.field,
    hidden: x.props.hidden,
  }));

  onColumnsSubmit(hiddenColumnInfo);
}
