import styled from 'styled-components';

const StyledWrapper = styled.label`
    display: block;

    & + & {
        margin-top: 0.75rem;
    }
`;

export default StyledWrapper;
