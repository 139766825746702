import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import withColors from 'common/hoc/withColors';
import { scrollToElement } from 'utils/utils';
import icons from 'utils/icons';

import StyledButton from './StyledButton';
import StyledHeader from './StyledHeader';
import StyledIcon from './StyledIcon';
import StyledWrapper from './StyledWrapper';
import StyledContentWrapper from './StyledContentWrapper';

const propTypes = {
    step: PropTypes.number.isRequired,
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    showButton: PropTypes.bool.isRequired
};

const defaultProps = {
    title: undefined,
    showButton: false
};

const SectionStep = ({ step, title, children, showButton, ...others }) => {
    // TODO Vorbert -> ogarnać, by ten mechanizm był używany mniej "na czuja" xD
    const handleScrollToSection = () => {
        const section = document.querySelectorAll(
            `[data-section-number="${step}"]`
        )[1];

        section && scrollToElement(section);
    };
    const { t } = useTranslation();

    return (
        <StyledWrapper {...others}>
            <StyledIcon icon={step} />
            <StyledContentWrapper>
                {title && <StyledHeader>{title}</StyledHeader>}
                {children}
                {showButton && (
                    <StyledButton
                        icon={icons.arrowDown}
                        onClick={handleScrollToSection}
                    >
                        {t('SectionStep.GoToTheStep')}
                    </StyledButton>
                )}
            </StyledContentWrapper>
        </StyledWrapper>
    );
};

SectionStep.propTypes = propTypes;
SectionStep.defaultProps = defaultProps;

export default memo(withColors(SectionStep));
