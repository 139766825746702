import gridNames from 'common/gridNames';
import { gridStoreResults } from 'FoxReact/FoxGrid/actions';
import NotificationTemplateSrv from './NotificationTemplateSrv';

export function storeResults(results) {
    return gridStoreResults(gridNames.administrationNotificationTemplates)(
        results
    );
}

export function getForGrid(dataState) {
    return function (dispatch) {
        return NotificationTemplateSrv.getForGrid(dataState).then(results =>
            dispatch(storeResults(results))
        );
    };
}
