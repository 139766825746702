import styled, { css } from 'styled-components';

const RootSectionWrapper = styled.section`
    --section-offset: min(${({ theme }) => theme.mobilePageOffset}, 38px);
    padding: var(--section-offset);

    & + & {
        padding-top: 0;
    }

    /* NOTE: Props przydatny w wypadku gdy chcemy wymusić posiadanie paddingu (na topie), dla sekcji która nie jest ostatnia */
    ${({ withTopOffset }) =>
        withTopOffset &&
        css`
            padding-top: var(--section-offset) !important;
        `}
`;

export default RootSectionWrapper;
