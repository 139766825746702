import styled from 'styled-components';
import { pxToRem } from 'utils/stylingUtils';

const StyledWrapper = styled.div`
    max-height: 75vh;
    max-width: 100%;
    padding: ${pxToRem(16)};
    background-color: white;
    color: ${({ theme }) => theme.dark};
    border-radius: 12px;
    overflow: auto;
    /* NOTE: To jest taki sam rozmiar, jak znajduje się w szablonach email */
    font-size: 16px;
`;

export default StyledWrapper;
