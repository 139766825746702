export const mapInvoiceDetailsPositions = (positions = []) => positions.map(
    (position, positionIndex) => ({
        index: {
            value: positionIndex + 1
        },
        name: {
            value: position.name
        },
        unit: {
            value: position.unit
        },
        quantity: {
            value: position.quantity
        }
    })
);
