import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/stylingUtils';
import withColors from 'common/hoc/withColors';

const Label = styled.span`
    --background-color: var(--color, ${({ theme }) => theme.info});
    --content-color: var(--text-color, ${({ theme }) => theme.light});

    display: inline-block;
    background-color: var(--background-color);
    color: var(--content-color);
    border-radius: 2rem;
    padding: ${pxToRem(6)} ${pxToRem(16)};
    font-size: 0.75rem;
    line-height: 1;
    font-weight: bold;
    text-transform: uppercase;

    ${({ textCase }) =>
        textCase &&
        css`
            text-transform: ${textCase};
        `}

    &:not(:first-child) {
        margin-left: ${pxToRem(12)};
    }

    /* TODO Vorbert -> zastanowić się nad propem (boolean) dającym ten margin-left dla pierwszego dziecka */
`;

export default withColors(Label);
