import InvoicesContainer from 'Administration/Invoices/InvoicesContainer';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import InvoiceConfirmationContainer from './InvoiceConfirmation/InvoiceConfirmationContainer';
import InvoiceContainer from './Administration/Invoices/InvoiceContainer';
import Layout from 'common/Layout';
import withTheme from 'common/hoc/withTheme';
import 'styles/main.scss';
import 'styles/fontAwesome.scss';
import 'styles/kendo/styles.scss';
import urls from 'common/urls';
import NotificationTemplatesContainer from './Administration/NotificationTemplates/NotificationTemplatesContainer';
import NotificationTemplateEditContainer from './Administration/NotificationTemplates/NotificationTemplateEditContainer';
import PrivateRoute from 'Auth/PrivateRoute';
import Login from 'Auth/Login';
import Page404 from 'Auth/Page404/Page404';
import Page401 from 'Auth/Page401/Page401';
import LandingPage from 'LandingPage';
import Toasts from 'Toasts';

function App() {
    const { t } = useTranslation();

    return (
        <Suspense fallback={t('Loading')}>
            <Router>
                <Layout>
                    <Switch>
                        <Route
                            path={urls.invoiceConfirmation(':confirmationId')}
                            children={<InvoiceConfirmationContainer />}
                        />
                        <PrivateRoute
                            path={urls.administrationInvoices}
                            children={<InvoicesContainer />}
                        />
                        <PrivateRoute
                            path={urls.administrationInvoice(':invoiceId')}
                            children={<InvoiceContainer />}
                        />
                        <PrivateRoute
                            path={urls.administrationNotificationTemplates}
                            children={<NotificationTemplatesContainer />}
                        />
                        <PrivateRoute
                            path={urls.administrationNotificationTemplate(
                                ':notificationTemplateId'
                            )}
                            children={<NotificationTemplateEditContainer />}
                        />
                        <Route
                            path={urls.landingPage}
                            exact
                            children={<LandingPage />}
                        />
                        <Route path={urls.login} children={<Login />} />
                        <Route path={urls.page401} children={<Page401 />} />
                        <Route children={<Page404 />} />
                    </Switch>
                </Layout>
            </Router>
            <Toasts />
        </Suspense>
    );
}

export default withTheme(App);
