import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import OffsetWrapper from 'common/OffsetWrapper';
import Section from 'common/Section';
import icons from 'utils/icons';
import Button from 'common/Button';
import React, { memo, useState } from 'react';
import { colorTypes } from '../../utils/consts';
import styled from 'styled-components';
import FoxConfirmationDialog from '../../FoxReact/FoxConfirmationDialog';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

function AdministrationActions({ details, toggleAlerts, removeInvoice }) {
    const { t } = useTranslation();
    const [showConfirmation, setShowConfirmation] = useState();

    return (
        <>
            {showConfirmation && <FoxConfirmationDialog
                title={"Czy na pewno chcesz oznaczyć fakturę jako usunięta?"}
                body={"Tej operacji nie da się wycofać."}
                onYesClick={removeInvoice}
                onNoClick={() => setShowConfirmation(false)}
                onCloseClick={() => setShowConfirmation(false)} />}
            <OffsetWrapper>
                <Section icon={icons.exclamation} iconType='solid'>
                    <ButtonContainer>
                        <Button icon={icons.bell}
                                onClick={toggleAlerts}>{t(details.alertsDisabled ? 'Admin.Invoice.EnableAlerts' : 'Admin.Invoice.DisableAlerts')}</Button>
                        <Button colorType={colorTypes.error} icon={icons.trash} onClick={() => setShowConfirmation(true)}>{t('Admin.Invoice.RemoveInvoice')}</Button>
                    </ButtonContainer>
                </Section>
            </OffsetWrapper>
        </>
    )
}

AdministrationActions.propTypes = {
    details: PropTypes.object,
    toggleAlerts: PropTypes.func,
    removeInvoice: PropTypes.func,
};

export default memo(AdministrationActions);
