import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { colorTypes } from 'utils/consts';

import StyledLabel from './StyledLabel';

const propTypes = {
    isReminder: PropTypes.bool,
    isCritical: PropTypes.bool
};

const defaultProps = {
    isReminder: false,
    isCritical: false
};

const InvoiceLabel = ({ isReminder, isCritical, ...passingProps }) => {
    const { t } = useTranslation();
    const displayLabel = isReminder || isCritical;
    const { content, colorType } = useMemo(
        () =>
            isReminder
                ? {
                      content: 'Invoice.Label.Reminder',
                      colorType: colorTypes.info
                  }
                : {
                      content: 'Invoice.Label.Monit',
                      colorType: colorTypes.error
                  },
        [isReminder]
    );

    return (
        displayLabel && (
            <StyledLabel colorType={colorType} {...passingProps}>
                {t(content)}
            </StyledLabel>
        )
    );
};

InvoiceLabel.propTypes = propTypes;
InvoiceLabel.defaultProps = defaultProps;

export default memo(InvoiceLabel);
