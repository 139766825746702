import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n.use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'en',
        keySeparator: false,
        debug: true,
        interpolation: {
            escapeValue: false
        },
        react: {
            wait: true,
            useSuspense: false
        }
    });

export default i18n;
