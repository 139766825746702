import React, { memo } from 'react';
import PropTypes from 'prop-types';
import createFoxGridContainer from 'FoxReact/FoxGrid/createFoxGridContainer';
import gridNames from 'common/gridNames';
import FoxGridColumn from 'FoxReact/FoxGrid/FoxGridColumn';
import FoxGridCell from 'FoxReact/FoxGrid/FoxGridCell';
import { Link } from 'react-router-dom';
import urls from 'common/urls';

const propTypes = {
    onLoadData: PropTypes.func.isRequired
};

const defaultProps = {};

const FoxGridContainer = createFoxGridContainer(
    gridNames.administrationNotificationTemplates
);

function NotificationTemplates({ onLoadData }) {
    return (
        <FoxGridContainer onLoadData={onLoadData}>
            <FoxGridColumn field="name" title="Nazwa" />
            <FoxGridColumn
                field="edit"
                title="Akcja"
                cell={({ dataItem, ...others }) => (
                    <FoxGridCell dataItem={dataItem} {...others}>
                        <Link
                            to={urls.administrationNotificationTemplate(
                                dataItem.id
                            )}
                        >
                            Edytuj
                        </Link>
                    </FoxGridCell>
                )}
            />
        </FoxGridContainer>
    );
}

NotificationTemplates.propTypes = propTypes;
NotificationTemplates.defaultProps = defaultProps;

export default memo(NotificationTemplates);
