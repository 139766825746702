import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { toCorrectCssUnit } from 'utils/utils';

const propTypes = {
    size: PropTypes.number,
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

const defaultProps = {
    size: 1,
    minWidth: undefined
};

const Col = styled.div`
    flex: ${({ size }) => size};

    /* NOTE: IE fix */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex: ${({ size }) => `${size} ${size}`} auto;
    }

    ${({ minWidth }) =>
        minWidth &&
        css`
            min-width: ${toCorrectCssUnit(minWidth)};
        `}

    & + & {
        @media screen and (min-width: 1025px) {
            margin-left: ${({ theme }) => theme.defaultHorizontalMargin};
        }

        @media screen and (max-width: 1024px) {
            margin-top: ${({ theme }) => theme.defaultVerticalMargin};
        }

        /* TODO Vorbert -> ogarnąć te wielkości z offsetWrapper */
        @media screen and (max-width: 1024px) {
            margin-top: calc(
                ${({ theme }) => theme.defaultVerticalMargin} * 1.5
            );
        }
    }
`;

Col.propTypes = propTypes;
Col.defaultProps = defaultProps;

export default Col;
