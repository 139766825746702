export const getElementPropertyValueString = (element, propertyName) =>
    element && window.getComputedStyle(element).getPropertyValue(propertyName);

export const getElementPropertyValue = (element, propertyName) =>
    element &&
    parseFloat(window.getComputedStyle(element).getPropertyValue(propertyName));

export const getElementWidth = element =>
    getElementPropertyValue(element, 'width');

export const getElementHeight = element =>
    getElementPropertyValue(element, 'height');
