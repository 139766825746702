import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/stylingUtils';
import OffsetWrapper from 'common/OffsetWrapper';
import { isUsingInternetExplorer } from 'utils/consts';

const StyledWrapper = styled(OffsetWrapper)`
    --horizontal-padding: ${pxToRem(24)};
    --vertical-padding: ${pxToRem(18)};

    position: relative;
    padding: var(--vertical-padding) var(--horizontal-padding);
    background-color: ${({ theme }) => theme.opaqueDark};
    border-radius: 22px;

    ${({ withIcon }) =>
        withIcon &&
        css`
            --icon-size: ${pxToRem(72)};
            --icon-font-size: ${pxToRem(36)};
            --icon-horizontal-offset: calc(
                var(--horizontal-padding) + calc(var(--icon-size) * 0.5)
            );
            --icon-vertical-offset: ${pxToRem(24)};

            padding-left: var(--icon-horizontal-offset);
            margin-left: calc(var(--icon-size) * 0.5);
            margin-top: var(--icon-vertical-offset);

            @media screen and (max-width: 476px) {
                --vertical-padding: ${pxToRem(24)};

                padding-left: var(--horizontal-padding);
                margin-left: 0;
                text-align: center;
            }

            @media screen and (min-width: 768px) and (max-width: 1440px) {
                --icon-size: ${pxToRem(64)};
                --icon-font-size: ${pxToRem(32)};
            }
        `}

    ${({ moreVerticalOffset }) =>
        moreVerticalOffset &&
        css`
            --vertical-padding: ${pxToRem(24)};
        `}

    ${isUsingInternetExplorer &&
    css`
        padding: 1rem;
    `}
`;

export default StyledWrapper;
