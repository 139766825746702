import React from 'react';
import PropTypes from 'prop-types';
import { colorTypes } from 'utils/consts';
import cx from 'classnames';

import styles from './withColors.module.scss';

const propTypes = {
    colorType: PropTypes.oneOf(Object.keys(colorTypes))
};

const defaultProps = {
    colorType: undefined
};

const withColors = Component => {
    const wrappedComponent = props => {
        const colorsClassName = cx(props.className, {
            [styles[`colorProvider--${props.colorType}`]]:
                props.colorType && !props.disabled,
            [styles['colorProvider--disabled']]: props.disabled
        });

        return <Component {...props} className={colorsClassName} />;
    };

    wrappedComponent.propTypes = propTypes;
    wrappedComponent.defaultProps = defaultProps;

    return wrappedComponent;
};

export default withColors;
