import styled from 'styled-components';
import SectionIcon from 'common/SectionIcon';

const StyledIcon = styled(SectionIcon)`
    margin-right: var(--horizontal-padding);

    @media screen and (max-width: 760px) {
        margin: 0 auto;
        margin-bottom: ${2 / 3}rem;
    }
`;

export default StyledIcon;
