import React, { memo } from 'react';
import moment from 'moment';
import { aproSystemUrl } from 'utils/consts';
import { useTranslation } from 'react-i18next';
import RootSectionWrapper from 'common/RootSectionWrapper';
import Row from 'common/Row';
import styles from './Footer.module.scss';

const propTypes = {};

const defaultProps = {};

const { REACT_APP_GIT_VERSION } = process.env;

function Footer() {
    const { t } = useTranslation();

    return (
        <footer>
            <RootSectionWrapper className={styles.companyInformation}>
                <Row
                    justifyContent="space-between"
                    breakOnMobile
                    className={styles.widthWrapper}
                >
                    <div className={styles.column}>
                        <div className={styles.accentedText}>
                            VIDOK Sp. z.o.o
                        </div>
                        <div>Rudna Mała 75</div>
                        <div>36-054 Mrowla</div>
                        <div>{t('Footer.CompanyCountry')}</div>
                    </div>
                    <div className={styles.column}>
                        <div>KRS: Nr 0000191980</div>
                        <div>REGON: 690379060</div>
                        <div>NIP: 813-10-86-710</div>
                        <div>Euro-NIP: PL8131086710</div>
                    </div>
                    <div className={styles.column}>
                        <div>
                            {`${t('Footer.Website')}: `}
                            <a
                                href={'https://www.vidok.com'}
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.vidok.com
                            </a>
                        </div>
                        <div>
                            {`${t('Email')}: `}
                            <a href={'mailto:biuro@vidok.com'}>
                                biuro@vidok.com
                            </a>
                        </div>
                        <div>Fax: +48 17/ 859 56 61 - 62</div>
                        <div>
                            {`${t('Footer.Telephone')}: `}
                            <a href={'tel:+48178595659'}>
                                +48 17/ 859 56 59 - 60
                            </a>
                        </div>
                    </div>
                </Row>
            </RootSectionWrapper>
            <RootSectionWrapper withTopOffset>
                <Row
                    justifyContent="space-between"
                    alignItems="center"
                    breakOnMobile
                    className={styles.widthWrapper}
                >
                    <span className={styles.accentedText}>
                        {t('Footer.Contribution')}
                    </span>
                    <span>{`${REACT_APP_GIT_VERSION}`}</span>
                    <span>
                        <a
                            href={aproSystemUrl}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.accentedText}
                        >
                            © {moment().year()} APROSYSTEM
                        </a>
                    </span>
                </Row>
            </RootSectionWrapper>
        </footer>
    );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default memo(Footer);
