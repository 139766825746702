import React, { memo } from 'react';
import styled, { keyframes } from 'styled-components';

const svgRotateAnimation = keyframes`
    0% {
        transform: rotateZ(0deg);
    }

    100% {
        transform: rotateZ(360deg);
    }
`;

const circleFillingAnimation = keyframes`
    0%,
    25% {
        stroke-dashoffset: 280;
        transform: rotate(0);
    }

    50%,
    75% {
        stroke-dashoffset: 75;
        transform: rotate(45deg);
    }

    100% {
        stroke-dashoffset: 280;
        transform: rotate(360deg);
    }
`;

const StyledWrapper = styled.svg`
    display: block;
    margin: 0 auto;
    animation: 1.75s linear infinite ${svgRotateAnimation};
`;

const StyledCircle = styled.circle`
    fill: transparent;
    stroke: var(--text-color, ${({ theme }) => theme.accent});
    stroke-width: 8;
    stroke-dasharray: 290;
    stroke-linecap: round;
    transform-origin: 50% 50%;
    animation: 1.8s ease-in-out infinite both ${circleFillingAnimation};
`;

const StyledSvg = props => {
    return (
        <StyledWrapper
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <StyledCircle cx={50} cy={50} r={46} />
        </StyledWrapper>
    );
};

export default memo(StyledSvg);
