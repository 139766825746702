export const aproSystemUrl = 'https://www.aprosystem.pl/';
export const supportedLangs = ['en', 'pl', 'it', 'fr', 'de', 'sk'];
export const defaultLang = 'pl';

export const colorTypes = {
    accent: 'accent',
    info: 'info',
    error: 'error',
    success: 'success',
    dark: 'dark',
    light: 'light'
};

export const dateAndHourFormat = 'DD.MM.yyyy HH:mm';

export const availableCssSizeUnits = ['px', '%'];

export const toastTypes = {
    success: 'success',
    info: 'info',
    warning: 'warning',
    error: 'error',
    notification: 'notification'
};

// NOTE: https://www.digitalocean.com/community/tutorials/js-speed-up-scroll-events
export const passiveListenerParamters = {
    capture: true,
    passive: true
};

export const invoiceLogTypes = {
    downloadedInvoice: 'confirmation_process_invoice_downloaded',
    confirmedPickup: 'confirmation_process_goods_received',
    notificationCreated: 'notification_created'
};

export const invoiceHighlightedLogTypes = [
    invoiceLogTypes.downloadedInvoice,
    invoiceLogTypes.confirmedPickup
];

export const acceptCookieName = 'CookieConsent';

export const notificationType = {
    invoiceConfirmation: 1,
    invoiceConfirmationReminder: 2,
    criticalConfirmation: 3
};

export const isUsingInternetExplorer = window.isUsingIE;
