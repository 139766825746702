import styled from 'styled-components';
import Button from 'common/Button';

const StyledButton = styled(Button)`
    @media screen and (max-width: 760px) {
        margin: 0 auto;
    }
`;

export default StyledButton;
