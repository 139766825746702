import styled from 'styled-components';
import Button from 'common/Button';
import { pxToRem } from 'utils/stylingUtils';

const StyledButton = styled(Button)`
    margin-top: ${pxToRem(6)} !important;

    @media screen and (max-width: 476px) {
        margin-top: ${pxToRem(8)} !important;
    }
`;

export default StyledButton;
