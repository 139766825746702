import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/stylingUtils';
import { isUsingInternetExplorer } from 'utils/consts';

const StyledWrapper = styled.div`
    --size: var(--icon-size, ${pxToRem(72)});
    --font-size: var(--icon-font-size, ${pxToRem(36)});

    display: flex;
    box-sizing: border-box;
    width: var(--size);
    height: var(--size);
    align-items: center;
    justify-content: center;
    background-color: var(--color, ${({ theme }) => theme.lightenDark});
    box-shadow: 0 1px 24px -4px ${({ theme }) => theme.opaqueDark};
    color: var(--text-color, ${({ theme }) => theme.light});
    font-size: var(--font-size);
    text-align: center;
    border-radius: 12px;
    font-weight: bold;
    line-height: 1;

    ${isUsingInternetExplorer &&
    css`
        position: absolute;
        top: 0;
        opacity: 0;
    `}
`;

export default StyledWrapper;
