import { uniqueId } from 'lodash';
import moment from 'moment';
import actionTypes from './actionTypes';

const initialState = {
    notifications: []
};

export default function toastsReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.TOAST_DISPLAY:
            return {
                notifications: [
                    ...state.notifications,
                    {
                        id: uniqueId(),
                        message: action.message,
                        type: action.toastType,
                        duration: action.duration,
                        creationTime: moment().valueOf()
                    }
                ]
            };
        case actionTypes.TOAST_HIDE:
            return {
                notifications: [
                    ...state.notifications.filter(x => x.id !== action.id)
                ]
            };
        default:
            return state;
    }
}
