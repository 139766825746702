import styled from 'styled-components';
import { pxToRem } from 'utils/stylingUtils';
import SectionIcon from 'common/SectionIcon';

const StyledIcon = styled(SectionIcon)`
    position: absolute;
    left: calc(var(--icon-size) * -0.5);
    top: calc(var(--icon-vertical-offset) * -1);
    scroll-margin-top: var(--icon-vertical-offset);

    @media screen and (max-width: 476px) {
        left: 50%;
        transform: translate(-50%, ${pxToRem(-32)});
    }
`;

export default StyledIcon;
