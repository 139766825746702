import React, { memo } from 'react';
import PropTypes from 'prop-types';
import 'tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';

import 'tinymce/plugins/image';
import 'tinymce/plugins/code';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/fullpage';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/wordcount';

import 'tinymce/skins/ui/oxide/skin.min.css';
import { Editor } from '@tinymce/tinymce-react';
import ExternalContentSrv from 'common/Services/ExternalContentSrv';

const propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

const defaultProps = {};

const plugins = `
    image
    code
    advlist
    anchor
    autolink
    autoresize
    charmap
    code
    contextmenu
    directionality
    fullpage
    fullscreen
    hr
    imagetools
    importcss
    link
    lists
    paste
    preview
    searchreplace
    textcolor
    wordcount
    imagtools
    `;

const init = {
    height: 500,
    menubar: true,
    plugins: [plugins],
    image_advtab: true,
    paste_data_images: true,
    relative_urls: false,
    remove_script_host: false,
    convert_urls: false,
    image_caption: true,
    images_upload_handler: ExternalContentSrv.uploadHtmlExternalContent,

    toolbar:
        'formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code'
};

function FoxHtmlEditor({ value, onChange }) {
    return (
        <Editor
            init={init}
            value={value}
            onEditorChange={content => onChange({ target: { value: content } })}
        />
    );
}

FoxHtmlEditor.propTypes = propTypes;
FoxHtmlEditor.defaultProps = defaultProps;

// FoxHtmlEditor.whyDidYouRender = {
//     logOnDifferentValues: true
// };

export default memo(FoxHtmlEditor);
