// Default Grid filter operators:
const foxGridFilterOperators = {
    text: [
        { text: "grid.filterContainsOperator", operator: "contains" },
        { text: "grid.filterEqOperator", operator: "eqs" },
        { text: "grid.filterNotEqOperator", operator: "neq" },
        { text: "grid.filterStartsWithOperator", operator: "startswith" },
        { text: "grid.filterEndsWithOperator", operator: "endswith" },
        { text: "grid.filterIsNullOperator", operator: "isnull" },
        { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
        { text: "grid.filterIsEmptyOperator", operator: "isempty" },
        { text: "grid.filterIsNotEmptyOperator", operator: "isnotempty" },
    ],
    numeric: [
        { text: "grid.filterEqOperator", operator: "eqn" },
        { text: "grid.filterNotEqOperator", operator: "neq" },
        { text: "grid.filterIsNullOperator", operator: "isnull" },
        { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
    ],
    date: [
        { text: "grid.filterNotEqOperator", operator: "dneq" },
        { text: "grid.filterIsNullOperator", operator: "isnull" },
        { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
        { text: "grid.filterAfterOperator", operator: "dgt" },
        { text: "grid.filterBeforeOperator", operator: "dlt" },
    ],
};

export default foxGridFilterOperators;
