const createTemporaryTextArea = () => {
  const textArea = document.createElement('textarea');
  textArea.classList.add('invisible');
  textArea.style.position = 'absolute';
  textArea.style.left = '-9969px';
  textArea.setAttribute('readonly', 'readonly');
  return textArea;
};

const copyToClipboard = textToCopy => {
  const textArea = createTemporaryTextArea();
  textArea.value = textToCopy;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

export default copyToClipboard;
