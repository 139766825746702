import { combineReducers } from 'redux';
import administrationInvoicesReducer from 'Administration/Invoices/reducer';
import administrationNotificationTemplatesReducer from 'Administration/NotificationTemplates/reducer';
import toastsReducer from 'Toasts/reducer';
import authReducer from 'Auth/reducer';
import { reducer as formReducer } from 'redux-form';

export default combineReducers({
    administrationNotificationTemplates: administrationNotificationTemplatesReducer,
    administrationInvoices: administrationInvoicesReducer,
    toasts: toastsReducer,
    form: formReducer,
    auth: authReducer
});
