import styled, { keyframes } from 'styled-components';

const appearingAnimation = keyframes`
    0% {
        opacity: 0;
        transform: translateY(5em) scale(0.75);
    }

    100% {
        opacity: 1;
        transform: translate(0) scale(1);
    }
`;

// TODO Vorbert -> wyekstraktować komponent zrobić / HOC-a
const StyledContentWrapper = styled.div`
    animation: 750ms ease forwards ${appearingAnimation};
`;

export default StyledContentWrapper;
