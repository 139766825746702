import { useEffect, useState, useRef, useMemo } from 'react';
import { elementHasBeenScrolled, elementIsVisibleInViewport } from 'utils';
import { passiveListenerParamters } from 'utils/consts';
import debounce from 'debounce';

export const useOutsideClick = (componentRef, callback) => {
    const handleClickOutside = event => {
        if (
            componentRef.current &&
            !componentRef.current.contains(event.target)
        ) {
            callback();
        }
    };

    useEffect(() => {
        window.addEventListener('mousedown', handleClickOutside);
        return () =>
            window.removeEventListener('mousedown', handleClickOutside);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentRef, callback]);
};

export const useViewportDimensions = () => {
    const getViewportDimensions = ({
        innerWidth: width,
        innerHeight: height
    }) => [width, height];

    const [viewportDimensions, setViewportDimensions] = useState(() =>
        getViewportDimensions(window)
    );

    const handleResize = e => {
        const dimensions = getViewportDimensions(e.currentTarget);
        setViewportDimensions(dimensions);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.addEventListener('resize', handleResize);
        // eslint-disable-next-line
    }, []);

    return viewportDimensions;
};

// NOTE: ten hook zwraca refa, więc do wartości można dostać sie poprzez 'current', znajdujacą się w obiekcie 'stateValueRef'
export const useStateRef = initialValue => {
    const [stateValue, _setStateValue] = useState(initialValue);
    const stateValueRef = useRef(stateValue);

    const setStateValue = value => {
        if (value !== stateValueRef.current) {
            stateValueRef.current = value;
            _setStateValue(value);
        }
    };

    return [stateValueRef, setStateValue];
};

export const useHasBeenScrolled = (
    targetElementRef,
    isMobileOnly = false,
    viewportWidth
) => {
    const [hasBeenScrolled, setHasBeenScrolled] = useStateRef(false);

    const handleTableScroll = e => {
        const hasBeenScrolled = elementHasBeenScrolled(e.currentTarget);
        setHasBeenScrolled(hasBeenScrolled);
    };

    useEffect(() => {
        if (!isMobileOnly && viewportWidth > 760) {
            const { current: tableElement } = targetElementRef;
            tableElement.addEventListener('scroll', handleTableScroll);
            return () =>
                tableElement.removeEventListener('scroll', handleTableScroll);
        }
        // eslint-disable-next-line
    }, [viewportWidth]);

    return [hasBeenScrolled];
};

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            const intervalId = setInterval(tick, delay);
            return () => clearInterval(intervalId);
        }
        return undefined;
    }, [delay]);
};

export const useHasBeenSeen = elementRef => {
    const [hasBeenSeen, setHasBeenSeen] = useStateRef(false);

    useEffect(() => {
        if (elementRef) {
            const isElementVisibleOnScreen = debounce(() => {
                const isCurrentlyVisible = elementIsVisibleInViewport(
                    elementRef.current
                );

                if (isCurrentlyVisible) {
                    setHasBeenSeen(true);
                    window.removeEventListener(
                        'scroll',
                        isElementVisibleOnScreen,
                        passiveListenerParamters
                    );
                }
            }, 50);

            isElementVisibleOnScreen();

            if (!hasBeenSeen.current) {
                window.addEventListener(
                    'scroll',
                    isElementVisibleOnScreen,
                    passiveListenerParamters
                );

                return () =>
                    window.removeEventListener(
                        'scroll',
                        isElementVisibleOnScreen,
                        passiveListenerParamters
                    );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementRef]);

    return hasBeenSeen.current;
};

/**
 *
 * @param {Boolean = false} defaultValue
 * @returns {Array} [value: boolean, toggle: fn()]
 */
export const useToggle = (defaultValue = false) => {
    const [value, setValue] = useState(defaultValue);
    const toggle = () => setValue(!value);

    return [value, toggle];
};

// NOTE: jest to hook, który przyjmuje wartości, które powinny być zwracane w zależności od tego jaką toggle ma wartość
// Służy on do tego, aby w renderze było mniej warunków oraz ich duplikatów :D
export const useToggleValue = (
    isToggledByDefault = false,
    truthyValue = {},
    falsyValue = {}
) => {
    const [isToggled, setIsToggled] = useToggle(isToggledByDefault);
    const value = useMemo(() => (isToggled ? truthyValue : falsyValue), [
        isToggled,
        falsyValue,
        truthyValue
    ]);

    return [isToggled, value, setIsToggled];
};
