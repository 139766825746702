import set from 'lodash/set';
import initialState from './initialState';
import { setNewSort } from './utils';

const applyColumnsProps = (currentColumnsInfo, newColumnsProps) => {
  const newColumnsInfo = [...currentColumnsInfo];

  for (let i = 0; i < newColumnsProps.length; i++) {
    const toUpdate = newColumnsInfo.find(
      ci => ci.field === newColumnsProps[i].field,
    );
    if (toUpdate) {
      Object.assign(toUpdate, { ...newColumnsProps[i] });
    } else {
      newColumnsInfo.push({ ...newColumnsProps[i] });
    }
  }

  return [...newColumnsInfo];
};

const foxGridReducer = prefix => (state = initialState, action) => {
  if (!state.results) {
    set(state, 'results', initialState.results);
  }

  if (!state.dataState) {
    set(state, 'dataState', initialState.dataState);
  }

  switch (action.type) {
    case `${prefix}_STORE_RESULTS`:
      return {
        ...state,
        results: action.results,
      };
    case `${prefix}_STORE_GROUP`:
      return {
        ...state,
        dataState: {
          ...state.dataState,
          group: action.group,
        },
      };
    case `${prefix}_STORE_DATASTATE`:
      return {
        ...state,
        dataState: action.dataState,
      };
    case `${prefix}_SET_DEFAULT_SORT`:
      return {
        ...state,
        dataState: {
          ...setNewSort(state.dataState, action.defaultSort),
        },
      };
    case `${prefix}_CLEAR_FILTERS`:
      return {
        ...state,
        dataState: {
          ...state.dataState,
          filter: undefined,
        },
      };
    case `${prefix}_SET_NEW_ORDER`:
    case `${prefix}_SET_COLUMNS_SIZES`:
    case `${prefix}_SET_COLUMNS_SUBMIT`:
      return {
        ...state,
        columnsInfo: applyColumnsProps(state.columnsInfo, action.payload),
      };
    default:
      return state;
  }
};

export default foxGridReducer;
