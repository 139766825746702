import React, { memo } from 'react';
import PropTypes from 'prop-types';

import InvoiceLabel from './InvoiceLabel';
import StyledWrapper from './StyledWrapper';

const propTypes = {
    isReminder: PropTypes.bool,
    isCritical: PropTypes.bool
};

const defaultProps = {
    isReminder: false,
    isCritical: false
};

const InvoiceLabels = ({ isReminder, isCritical }) => {
    return (
        <StyledWrapper>
            <InvoiceLabel isReminder={isReminder} />
            <InvoiceLabel isCritical={isCritical} />
        </StyledWrapper>
    );
};

InvoiceLabels.propTypes = propTypes;
InvoiceLabels.defaultProps = defaultProps;

export default memo(InvoiceLabels);
