import React from 'react';
import PropTypes from 'prop-types';
import sharedPropTypes from 'utils/sharedPropTypes';

import StyledIcon from './StyledIcon';
import StyledLoader from './StyledLoader';
import StyledWrapper from './StyledWrapper';

// TODO Vorbert -> dodać możliwość ustawiania wielkosci przycisku: small / medium / large
const propTypes = {
    icon: sharedPropTypes.icon,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    children: PropTypes.node.isRequired
};

const defaultProps = {
    icon: undefined,
    disabled: false,
    isLoading: false
};

const Button = ({ icon, children, isLoading, disabled, ...others }) => {
    const displayIcon = icon && !isLoading;

    return (
        <StyledWrapper disabled={isLoading || disabled} {...others}>
            {isLoading && <StyledLoader size="1em" />}
            {/* TODO Vorbert -> zastanowić się nad ikonką typu solid dla przycisków */}
            {displayIcon && <StyledIcon icon={icon} />}
            <span>{children}</span>
        </StyledWrapper>
    );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
