import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Loader from 'common/Loader';

import StyledLabel from './StyledLabel';
import StyledWrapper from './StyledWrapper';
import StyledContentWrapper from './StyledContentWrapper';

const propTypes = {
    verticallyCenter: PropTypes.bool,
    isLoading: PropTypes.bool,
    label: PropTypes.string,
    children: PropTypes.node,
    renderContent: PropTypes.func
};

const defaultProps = {
    verticallyCenter: false,
    isLoading: false,
    label: undefined,
    children: <React.Fragment />,
    renderContent: children => (
        <StyledContentWrapper>{children}</StyledContentWrapper>
    )
};

const ContentLoader = ({
    verticallyCenter,
    label,
    isLoading,
    children,
    renderContent,
    ...others
}) => (
    <>
        {isLoading ? (
            <StyledWrapper {...others} verticallyCenter={verticallyCenter}>
                <Loader isLoading={isLoading} />
                {label && <StyledLabel>{label}</StyledLabel>}
            </StyledWrapper>
        ) : (
            renderContent(children)
        )}
    </>
);

ContentLoader.propTypes = propTypes;
ContentLoader.defaultProps = defaultProps;

export default memo(ContentLoader);
