import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from 'common/Button';
import StatusCodePage from 'common/StatusCodePage';

import styles from './Page404.module.scss';

const propTypes = {};

const defaultProps = {};

const Page404 = () => {
    const { goBack, length: historyLength } = useHistory();
    const goBackCanHappen = historyLength > 1;
    const { t } = useTranslation();

    const handleClick = () => {
        goBack();
    };

    return (
        <StatusCodePage
            statusCode={404}
            descriptionLong={t('Page404.Description.Long')}
            descriptionShort={t('Page404.Description.Short')}
        >
            {goBackCanHappen && (
                <Button onClick={handleClick} className={styles.buttonWrapper}>
                    {t('Page404.GoBack')}
                </Button>
            )}
        </StatusCodePage>
    );
};

Page404.propTypes = propTypes;
Page404.defaultProps = defaultProps;

export default memo(Page404);
