import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import StatusCodePage from 'common/StatusCodePage';

const propTypes = {};

const defaultProps = {};

const Page401 = () => {
    const { t } = useTranslation();

    return (
        <StatusCodePage
            statusCode={404}
            descriptionShort={t('Page401.Description.Short')}
        ></StatusCodePage>
    );
};

Page401.propTypes = propTypes;
Page401.defaultProps = defaultProps;

export default memo(Page401);
