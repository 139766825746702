/* eslint-disable react/prop-types */
import React from 'react';
import { Field } from 'redux-form';

const asReduxFormField = WrappedComponent => {
    function renderComponent({ input, meta, ...other }) {
        return (
            <React.Fragment>
                <WrappedComponent
                    {...input}
                    onChange={e => input.onChange(e.target.value)}
                    invalid={
                        meta.invalid && (meta.touched || meta.submitFailed)
                    }
                    {...other}
                    meta={meta}
                />
                {meta.invalid && (meta.touched || meta.submitFailed) && (
                    <span className="k-invalid-message">{meta.error}</span>
                )}
            </React.Fragment>
        );
    }

    return props => <Field {...props} component={renderComponent} />;
};

export default asReduxFormField;
