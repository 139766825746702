import axios from 'config/axios';

const prefix = 'administration/notificationTemplate';

const returnData = res => res.data;

export default class NotificationTemplateSrv {
    static getForGrid(dataState) {
        return axios.post(`${prefix}/getForGrid`, dataState).then(returnData);
    }

    static get(notificationTemplateId) {
        return axios
            .get(`${prefix}/${notificationTemplateId}`)
            .then(returnData);
    }

    static update(model) {
        return axios.put(`${prefix}`, model).then(returnData);
    }

    static generatePreview(invoiceId, currentDefinition) {
        return axios
            .post(`${prefix}/generatePreview`, { invoiceId, definition: currentDefinition })
            .then(returnData);
    }
}
