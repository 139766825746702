import icons from 'utils/icons';
import { toastTypes } from 'utils/consts';

export const getToastProperties = currentType => {
    switch (currentType) {
        case toastTypes.success:
            return {
                icon: icons.check,
                color: 'success'
            };
        case toastTypes.info:
            return {
                icon: icons.info,
                color: 'info'
            };
        case toastTypes.warning:
            return {
                icon: icons.exclamation,
                color: 'warning'
            };
        case toastTypes.error:
            return {
                icon: icons.exclamation,
                color: 'error'
            };
        default:
            return {
                icon: icons.bell,
                color: 'dark'
            };
    }
};
