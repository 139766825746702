import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useViewportDimensions, useHasBeenScrolled } from 'utils/customHooks';

import styles from './Table.module.scss';

const propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            numberValues: PropTypes.bool,
            width: PropTypes.string
        })
    ).isRequired,
    mobileHeaderWidth: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    items: PropTypes.arrayOf(PropTypes.object).isRequired
};

const defaultProps = {
    mobileHeaderWidth: '90px'
};

const Table = ({ columns, items, mobileHeaderWidth }) => {
    const tableRef = useRef(null);
    const [width] = useViewportDimensions();
    const [isStickyHeader] = useHasBeenScrolled(tableRef, false, width);
    const stickyHeaderClassName = cx({
        [styles.stickyHeader]: isStickyHeader.current
    });

    const getNumericClassName = isNumeric =>
        cx({
            [styles.numeric]: isNumeric
        });

    const getHeaderClassName = isNumeric =>
        cx(styles.headerCell, getNumericClassName(isNumeric));

    const getCellContentClassName = isNumeric =>
        cx(styles.headerCell, getNumericClassName(isNumeric));

    return (
        <table className={styles.tableContainer} ref={tableRef}>
            <thead className={stickyHeaderClassName}>
                <tr>
                    {columns.map(column => (
                        <th
                            key={column.name}
                            className={getHeaderClassName(column.numberValues)}
                            style={{
                                '--max-desktop-cell-width': column.width
                            }}
                            title={column.label}
                        >
                            {column.label}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {items.map((item, itemIndex) => (
                    <tr key={itemIndex}>
                        {columns.map((column, columnIndex) => (
                            <td
                                key={columnIndex}
                                className={getCellContentClassName(
                                    column.numberValues
                                )}
                                style={{
                                    '--max-desktop-cell-width': column.width
                                }}
                            >
                                <div
                                    className={styles.cellLabel}
                                    style={{ width: mobileHeaderWidth }}
                                >
                                    {column.label}
                                </div>
                                <span className={styles.cellContent}>
                                    {item[column.name].value || '-'}
                                </span>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export default memo(Table);
