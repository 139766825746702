import React, { memo } from 'react';
import PropTypes from 'prop-types';
import sharedPropTypes from 'utils/sharedPropTypes';

import StyledLink from './StyledLink';
import StyledIcon from './StyledIcon';

const propTypes = {
    icon: sharedPropTypes.icon,
    children: PropTypes.node.isRequired
};

const defaultProps = {
    icon: undefined
};

const Link = ({ children, icon, ...passedProps }) => {
    return (
        <StyledLink {...passedProps}>
            {children}
            {icon && <StyledIcon icon={icon} />}
        </StyledLink>
    );
};

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default memo(Link);
