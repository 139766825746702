import React from 'react';
import PropTypes from 'prop-types';
import { GridCell } from '@progress/kendo-react-grid';

const propTypes = {
    children: PropTypes.node
};

const defaultProps = {
    children: undefined
};

const FoxGridCell = ({ children, ...props }) => {
    return (
        <GridCell
            {...props}
            render={cellProps => <td {...cellProps}>{children || '-'}</td>}
        />
    );
};

FoxGridCell.propTypes = propTypes;
FoxGridCell.defaultProps = defaultProps;

export default FoxGridCell;
