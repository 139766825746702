import styled from 'styled-components';

const StyledRequiredSign = styled.span`
    margin-left: 0.25em;
    color: ${({ theme }) => theme.accent};
    font-weight: 700;
`;

StyledRequiredSign.defaultProps = {
    children: '*'
};

export default StyledRequiredSign;
