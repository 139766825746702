import React from 'react';
import PropTypes from 'prop-types';
import styles from './CircularProgressBar.module.scss';

const propTypes = {
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
  className: PropTypes.string,
  progress: PropTypes.number,
};

const defaultProps = {
  strokeWidth: 2,
  size: 32,
  className: '',
  progress: 0,
};

const CircularProgressBar = ({ size, strokeWidth, className, progress }) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;

  const getPercentage = () => circumference - (progress / 100) * circumference;

  return (
    <svg
      className={`${styles.progress_ring} ${className}`}
      width={size}
      height={size}
    >
      <circle
        r={radius}
        cx={size * 0.5}
        cy={size * 0.5}
        className={styles.circle}
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={getPercentage(progress)}
      />
    </svg>
  );
};

CircularProgressBar.propTypes = propTypes;
CircularProgressBar.defaultProps = defaultProps;

export default CircularProgressBar;
