import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ListItem from 'common/ListItem';

const propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.node
        ])
    ).isRequired
};

const defaultProps = {};

const MappedListItems = ({ items }) => {
    return items.map((item, itemIndex) => (
        <ListItem withLine={false} key={itemIndex}>
            {item}
        </ListItem>
    ));
};

MappedListItems.propTypes = propTypes;
MappedListItems.defaultProps = defaultProps;

export default memo(MappedListItems);
