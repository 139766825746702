import axios from 'config/axios';
import { getApiBaseAddress } from 'config/apiBaseAddress';

const prefix = 'externalContent';

export default class ExternalContentSrv {
    static upload(file, fileName) {
        const form = new FormData();
        form.append('file', file, fileName);

        return axios.post(prefix, form);
    }

    static uploadHtmlExternalContent(blobInfo, success, fail) {
        const blob = blobInfo.blob();
        const fileName = blobInfo.filename();
        ExternalContentSrv.upload(blob, fileName)
            .then(res => {
                const location = `${getApiBaseAddress()}externalContent/${
                    res.data
                }`;
                success(location);
            })
            .catch(() => fail());
    }
}
