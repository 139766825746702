import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withColors from 'common/hoc/withColors';
import Header from 'common/Header';

import MappedListItems from './MappedListItems';
import styles from './List.module.scss';

const propTypes = {
    title: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.node
        ])
    ),
    className: PropTypes.string,
    withOverflow: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node
    ])
};

const defaultProps = {
    title: undefined,
    className: undefined,
    withOverflow: false
};

// TODO Vorbert -> przerobić na styled-component z podawaną liczbą wyświetlanych rekordów (bez scrolla)
const List = ({ title, withOverflow, className, items, children }) => {
    const listClassName = cx(styles.list, {
        [styles.withOverflow]: withOverflow
    });

    const showChildren = !!children;

    return (
        <div className={className}>
            {title && <Header>{`${title}:`}</Header>}
            <ul className={listClassName}>
                {showChildren ? children : <MappedListItems items={items} />}
            </ul>
        </div>
    );
};

List.propTypes = propTypes;
List.defaultProps = defaultProps;

export default memo(withColors(List));
