import React, { memo } from 'react';
import PropTypes from 'prop-types';

import StyledWrapper from './StyledWrapper';
import StyledLabel from './StyledLabel';
import StyledRequiredSign from './StyledRequiredSign';

const propTypes = {
    children: PropTypes.any,
    label: PropTypes.string,
    required: PropTypes.bool,
    isSwitchField: PropTypes.bool
};

const defaultProps = {
    label: undefined,
    required: false,
    children: undefined,
    isSwitchField: false
};

function FormField({ children, label, required, isSwitchField }) {
    const containerTag = isSwitchField ? 'div' : 'label';

    return (
        <StyledWrapper as={containerTag}>
            <StyledLabel>
                {label}
                {required && <StyledRequiredSign />}
            </StyledLabel>
            {children}
        </StyledWrapper>
    );
}

FormField.propTypes = propTypes;
FormField.defaultProps = defaultProps;

export default memo(FormField);
