import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatAsTitleText } from 'utils';

import TimelineItemDescription from './TimelineItemDescription';
import NotificationContentDisplay from './NotificationContentDisplay';

const propTypes = {
    additionalData: PropTypes.object,
    content: PropTypes.string
};

const defaultProps = {
    additionalData: {}
};

function NotificationData({ additionalData }) {
    const { t } = useTranslation();

    return (
        <>
            <TimelineItemDescription>
                {`${formatAsTitleText(
                    t('Admin.Invoice.Timeline.Notification.SendTo')
                )} `}
                {additionalData.to}
            </TimelineItemDescription>
            <TimelineItemDescription>
                {`${formatAsTitleText(
                    t('Admin.Invoice.Timeline.Notification.Title')
                )} `}
                {additionalData.subject}
            </TimelineItemDescription>
            <NotificationContentDisplay content={additionalData.content} />
        </>
    );
}

NotificationData.propTypes = propTypes;
NotificationData.defaultProps = defaultProps;

export default memo(NotificationData);
