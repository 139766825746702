import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Column from 'common/Column';

import styles from './StatusCodePage.module.scss';

const propTypes = {
    children: PropTypes.node,
    statusCode: PropTypes.number.isRequired,
    descriptionShort: PropTypes.string.isRequired,
    descriptionLong: PropTypes.string
};

const defaultProps = {
    children: undefined
};

const StatusCodePage = ({
    children,
    statusCode,
    descriptionLong,
    descriptionShort
}) => {
    return (
        <Column as="section" className={styles.wrapper}>
            <h2 className={styles.statusCode}>{statusCode}</h2>
            <h4 className={styles.descriptionShort}>{descriptionShort}</h4>
            {descriptionLong && (
                <p className={styles.descriptionLong}>{descriptionLong}</p>
            )}
            {children}
        </Column>
    );
};

StatusCodePage.propTypes = propTypes;
StatusCodePage.defaultProps = defaultProps;

export default memo(StatusCodePage);
