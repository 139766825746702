import axios from "config/axios";
import { getApiBaseAddress } from "../config/apiBaseAddress";
import { saveAs } from 'file-saver';

const prefix = "invoiceConfirmation";

export default class InvoiceConfirmationSrv {
    static get(confirmationId) {
        return axios.get(`${prefix}/${confirmationId}`);
    }

    static confirmReceiptGoods(confirmationId) {
        return axios.post(`${prefix}/${confirmationId}/confirmReceiptGoods`);
    }

    static downloadInvoice(confirmationId) {
        saveAs(`${getApiBaseAddress()}${prefix}/${confirmationId}/downloadInvoice`);
    }
}
