import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import { Grid, GridToolbar } from "@progress/kendo-react-grid";
import { groupBy } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import {
    IntlProvider,
    load,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/pl/numbers.json";
import currencies from "cldr-numbers-full/main/pl/currencies.json";
import caGregorian from "cldr-dates-full/main/pl/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/pl/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/pl/timeZoneNames.json";
import FoxButton from "../FoxButton";
import foxGridFilterOperators from "./foxGridFilterOperators";
import plMessages from "./pl.json";
import { setNewSort } from "./utils";
import {
    setColumnWidth,
    getColumnHeaderHeight,
    getFilterResetVerticalOffset,
    scrollPageToGrid,
    addMissingTitleAttributes,
} from "./visualFunctions";
import {
    columnsSubmit,
    applayColumnInfo,
    columnReorder,
    columResize,
} from "./gridConfigurationFunctions";
import FoxGridColumn from "./FoxGridColumn";
import FoxGridColumnMenu from "./FoxGridColumnMenu";

/* eslint-disable react/no-unused-state */

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);

loadMessages(plMessages, "pl-PL");

const propTypes = {
    isSortable: PropTypes.bool,
    isFilterable: PropTypes.bool,
    isPageable: PropTypes.bool,
    isGroupable: PropTypes.bool,
    isReorderable: PropTypes.bool,
    isResizable: PropTypes.bool,
    onDataStateChange: PropTypes.func.isRequired,
    results: PropTypes.shape({
        items: PropTypes.any.isRequired,
        totalCount: PropTypes.number.isRequired,
    }).isRequired,
    children: PropTypes.any.isRequired,
    dataState: PropTypes.object.isRequired,
    pagerConfig: PropTypes.shape({
        type: PropTypes.oneOf(["numeric", "input"]),
        buttonCount: PropTypes.number,
        info: PropTypes.bool,
        pageSizes: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
        previousNext: PropTypes.bool,
    }),
    sortConfig: PropTypes.shape({
        allowUnsort: PropTypes.bool,
        mode: PropTypes.oneOf(["single", "multiple"]),
    }),
    onGroupChange: PropTypes.func.isRequired,
    loadOnStart: PropTypes.bool,
    onLoadData: PropTypes.func.isRequired,
    debounceLoadTime: PropTypes.number,
    defaultSortField: PropTypes.string,
    defaultSortDir: PropTypes.oneOf(["asc", "desc"]),
    onSetDefaultSort: PropTypes.func.isRequired,
    hasColumnMenu: PropTypes.bool,
    onResetFilters: PropTypes.func.isRequired,
    exportToExcelEnable: PropTypes.bool,
    customExcelItems: PropTypes.any,
    customExcelColumns: PropTypes.any,
    exportToPdfEnable: PropTypes.bool,
    customPdfItems: PropTypes.any,
    excelFileName: PropTypes.string,
    pdfFileName: PropTypes.string,
    renderButtons: PropTypes.node,
    renderRightButtons: PropTypes.node,
    onColumnReorder: PropTypes.func,
    columnsInfo: PropTypes.array,
    onColumnsSubmit: PropTypes.func,
    onColumnResize: PropTypes.func,
};

const defaultProps = {
    isSortable: true,
    isFilterable: true,
    isPageable: true,
    isGroupable: true,
    isReorderable: true,
    isResizable: true,
    pagerConfig: {
        type: "numeric",
        buttonCount: 5,
        info: true,
        pageSizes: [5, 10, 20, 50, 100, 200, 99999],
        previousNext: true,
    },
    sortConfig: {
        allowUnsort: false,
        mode: "single",
    },
    loadOnStart: true,
    debounceLoadTime: 300,
    defaultSortField: undefined,
    defaultSortDir: "asc",
    hasColumnMenu: true,
    exportToExcelEnable: true,
    customExcelItems: undefined,
    customExcelColumns: undefined,
    exportToPdfEnable: false,
    customPdfItems: undefined,
    excelFileName: "export.xlsx",
    pdfFileName: "export.pdf",
    renderButtons: undefined,
    renderRightButtons: undefined,
    onColumnReorder: () => {},
    columnsInfo: [],
    onColumnsSubmit: () => {},
    onColumnResize: () => {},
};

class FoxGrid extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loadingCount: 0,
            columns: this.props.children,
            filterButtonOffset: 0,
            exportingToPdf: false,
        };

        this.dataStateChange = this.dataStateChange.bind(this);
        this.getItems = this.getItems.bind(this);
        this.onGroupChange = this.onGroupChange.bind(this);
        this.columnsSubmit = columnsSubmit.bind(this);
        this.setColumnWidth = setColumnWidth.bind(this);
        this.getColumnHeaderHeight = getColumnHeaderHeight.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);
        this.exportToPdf = this.exportToPdf.bind(this);
        this.pdfExportDone = this.pdfExportDone.bind(this);
        this.getFilterResetVerticalOffset = getFilterResetVerticalOffset.bind(
            this
        );
        this.columnReorder = columnReorder.bind(this);
        this.applayColumnInfo = applayColumnInfo.bind(this);
        this.columnsSubmit = columnsSubmit.bind(this);
        this.columResize = columResize.bind(this);

        this.loadData = debounce(
            this.loadData.bind(this),
            this.props.debounceLoadTime
        );

        this.wrappedGridRef = null;
        this.gridRef = undefined;
        this.gridExcelExportRef = undefined;
        this.gridPdfExportRef = undefined;
    }

    componentDidMount() {
        const {
            loadOnStart,
            dataState,
            onDataStateChange,
            defaultSortField,
            defaultSortDir,
            onSetDefaultSort,
        } = this.props;

        let newDataState = { ...dataState };

        if (defaultSortField && !dataState.sort.length) {
            const sort = { field: defaultSortField, dir: defaultSortDir };
            onSetDefaultSort(sort);
            newDataState = setNewSort(dataState, sort);
        }

        loadOnStart &&
            onDataStateChange(newDataState) &&
            this.loadData(newDataState);

        this.setColumnWidth(this.wrappedGridRef);
        this.getFilterResetVerticalOffset();
        scrollPageToGrid();
        this.setState((prev) => ({
            columns: this.applayColumnInfo(prev.columns),
        }));

        addMissingTitleAttributes();
    }

    componentDidUpdate() {
        this.getFilterResetVerticalOffset();
    }

    onGroupChange(event) {
        this.props.onGroupChange(event.group);
    }

    getItems() {
        const { results, dataState } = this.props;

        if (!dataState.group) {
            return results.items;
        }

        const result = groupBy(results.items, dataState.group);
        return result;
    }

    loadData(dataState) {
        this.setState((state) => ({
            loadingCount: state.loadingCount + 1,
        }));
        this.props.onLoadData(dataState).finally(() => {
            this.setState((state) => ({
                loadingCount: state.loadingCount - 1,
            }));
        });
    }

    dataStateChange(event) {
        this.props.onDataStateChange(event.data);
        this.loadData(event.data);
    }

    resetFilters() {
        this.props
            .onResetFilters()
            .then(() => this.loadData(this.props.dataState));
    }

    exportToExcel() {
        const { customExcelItems, customExcelColumns } = this.props;
        const items = customExcelItems || this.getItems();
        const columns = customExcelColumns || this.gridRef.columns;
        this.gridExcelExportRef.save(items, columns);
    }

    exportToPdf() {
        const { customPdfItems } = this.props;
        const items = customPdfItems || this.getItems();
        this.gridPdfExportRef.save(items, this.pdfExportDone);
        this.setState({ exportingToPdf: true });
    }

    pdfExportDone() {
        this.setState({ exportingToPdf: false });
    }

    render() {
        const {
            isSortable,
            isFilterable,
            isPageable,
            isGroupable,
            isReorderable,
            isResizable,
            results,
            dataState,
            pagerConfig,
            sortConfig,
            exportToExcelEnable,
            exportToPdfEnable,
            excelFileName,
            pdfFileName,
            hasColumnMenu,
        } = this.props;

        const grid = (
            <Grid
                className={!!this.state.loadingCount && "fox_spinner"}
                filterOperators={foxGridFilterOperators}
                sortable={isSortable && sortConfig}
                filterable={isFilterable}
                pageable={isPageable && pagerConfig}
                groupable={isGroupable}
                reorderable={isReorderable}
                resizable={isResizable}
                data={this.getItems()}
                total={results.totalCount}
                filter={dataState.filter}
                group={dataState.group}
                skip={dataState.skip}
                take={dataState.take}
                sort={dataState.sort}
                onDataStateChange={this.dataStateChange}
                onGroupChange={this.onGroupChange}
                onColumnReorder={this.columnReorder}
                onColumnResize={this.columResize}
                ref={(gridRef) => {
                    this.gridRef = gridRef;
                }}
            >
                <GridToolbar>
                    {this.props.renderButtons}
                    {exportToExcelEnable && (
                        <FoxButton onClick={this.exportToExcel}>
                            Eksportuj do Excel
                        </FoxButton>
                    )}
                    {exportToPdfEnable && (
                        <FoxButton onClick={this.exportToPdf}>
                            Eksportuj do PDF
                        </FoxButton>
                    )}
                    {this.props.renderRightButtons}
                </GridToolbar>
                {!hasColumnMenu
                    ? this.state.columns
                    : this.state.columns.map(
                          (column) =>
                              !column.props.hidden && (
                                  <FoxGridColumn
                                      key={column.props.field}
                                      columnMenu={(props) => (
                                          <FoxGridColumnMenu
                                              {...props}
                                              columns={this.state.columns}
                                              onColumnsSubmit={
                                                  this.columnsSubmit
                                              }
                                          />
                                      )}
                                      {...column.props}
                                  />
                              )
                      )}
            </Grid>
        );

        const wrappedGrid = (
            <div
                className="grid_container"
                ref={(wrappedGridRef) => {
                    this.wrappedGridRef = wrappedGridRef;
                }}
            >
                <FoxButton
                    onClick={this.resetFilters}
                    icon="filter-clear"
                    primary
                    disabled={!dataState.filter || !!this.state.loadingCount}
                    className="fox-clear-button"
                    title={plMessages.grid.filterClearButton}
                    style={{ top: `${this.state.filterButtonOffset}px` }}
                />
                <LocalizationProvider language="pl-PL">
                    <IntlProvider locale="pl">{grid}</IntlProvider>
                </LocalizationProvider>
            </div>
        );

        return (
            <>
                {wrappedGrid}
                {exportToExcelEnable && (
                    <ExcelExport
                        ref={(excelExporter) => {
                            this.gridExcelExportRef = excelExporter;
                        }}
                        fileName={excelFileName}
                    />
                )}
                {exportToPdfEnable && (
                    <GridPDFExport
                        ref={(pdfExporter) => {
                            this.gridPdfExportRef = pdfExporter;
                        }}
                        fileName={pdfFileName}
                        margin="1cm"
                        paperSize="A4"
                    >
                        {grid}
                    </GridPDFExport>
                )}
            </>
        );
    }
}

FoxGrid.propTypes = propTypes;
FoxGrid.defaultProps = defaultProps;

export default FoxGrid;
