import React, { memo } from 'react';
import urls from 'common/urls';
import { Redirect, Route } from 'react-router-dom';
import useAuth from './useAuth';

const propTypes = {};

const defaultProps = {};

function PrivateRoute({ children, ...rest }) {
    const { isLogged } = useAuth();

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isLogged ? (
                    children
                ) : (
                    <Redirect
                        to={{ pathname: urls.login, state: { from: location } }}
                    />
                )
            }
        />
    );
}

PrivateRoute.propTypes = propTypes;
PrivateRoute.defaultProps = defaultProps;

export default memo(PrivateRoute);
