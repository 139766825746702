import styled, { css } from 'styled-components';
import withColors from 'common/hoc/withColors';
import { isUsingInternetExplorer } from 'utils/consts';

const StyledButtonWrapper = styled.button`
    --button-background-color: var(--color, ${({ theme }) => theme.accent});
    --button-hover-color: var(
        --saturated-color,
        ${({ theme }) => theme.saturatedAccent}
    );
    --button-text-color: var(--text-color, ${({ theme }) => theme.dark});
    --default-offset: ${2 / 3}em;

    display: flex;
    min-width: 75px;
    padding: ${2 / 3}em 1em;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;

    background-color: var(--button-background-color);
    border-radius: 8px;
    border: none;
    outline: none;

    color: var(--button-text-color);
    font-weight: bold;
    line-height: 1;
    text-decoration: none;
    transition: ${({ theme }) => theme.quickTransition};

    @media screen and (min-width: 768px) {
        & + & {
            margin-left: var(--default-offset);
        }

        &:not(:first-child) {
            margin-top: var(--default-offset);

            ${isUsingInternetExplorer &&
            css`
                margin-top: 8px;
            `}
        }
    }

    @media screen and (max-width: 768px) {
        min-width: 75px;
        justify-content: center;

        &:not(:first-child) {
            margin-top: var(--default-offset);
        }
    }

    @media screen and (max-width: 476px) {
        &:first-of-type:last-of-type {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &:hover,
    &:focus {
        background-color: var(--button-hover-color);
    }

    &:hover {
        cursor: pointer;
    }

    &:active {
        transform: scale(0.9);
    }

    &:disabled {
        transform: none;

        &:hover {
            cursor: not-allowed;
            background-color: var(--color);
        }

        ${isUsingInternetExplorer &&
        css`
            &,
            &:hover {
                background-color: ${({ theme }) => theme.grey};
            }
        `}
    }

    ${isUsingInternetExplorer &&
    css`
        background-color: ${({ theme }) => theme.accent};

        &:hover {
            background-color: ${({ theme }) => theme.saturatedAccent};
        }
    `}
`;

export default withColors(StyledButtonWrapper);
