import styled, { css } from 'styled-components';
import Text from 'common/Text';
import { pxToRem } from 'utils/stylingUtils';

const TimelineItemDescription = styled(Text)`
    font-size: 0.8em;

    & + & {
        margin-top: ${pxToRem(1.5)};
    }

    &:first-of-type {
        /* NOTE: wyrównywanie opisów timeline tak, by zgrywały się z jego animowaną linią */
        line-height: 1;
    }

    ${({ withoutTransparency }) =>
        !withoutTransparency &&
        css`
            opacity: 0.7;
        `}
`;

export default TimelineItemDescription;
