import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { FormSection, formValues, reduxForm } from 'redux-form';
import { compose } from 'redux';
import FoxInputTextField from 'FoxReact/FoxInputTextField';
import FoxButton from 'FoxReact/FoxButton';
import FoxHtmlEditorField from 'FoxReact/FoxHtmlEditor/FoxHtmlEditorField';
import styles from './NotificationTemplateEdit.module.scss';
import AvailablePlaceholders from './AvailablePlaceholders';
import PreviewGenerator from './PreviewGenerator';

const propTypes = {
    definitions: PropTypes.array.isRequired,
    availablePlaceholders: PropTypes.array.isRequired
};

const defaultProps = {
    definitions: [],
    availablePlaceholders: []
};

function NotificationTemplateEdit({
    definitions,
    submitting,
    handleSubmit,
    onSubmit,
    availablePlaceholders
}) {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleSelectTab = event => setSelectedTab(event.selected);

    return (
        <TabStrip selected={selectedTab} onSelect={handleSelectTab}>
            {definitions.map((x, index) => (
                <TabStripTab title={x.language} key={index}>
                    <section className={styles.formSectionWrapper}>
                        <FormSection name={`definitions[${index}]`}>
                            <AvailablePlaceholders
                                placeholders={availablePlaceholders}
                            />
                            <FoxInputTextField
                                label="Temat"
                                name="emailSubject"
                                required
                                fullWidth
                            />
                            <FoxInputTextField
                                label="DW"
                                name="emailCc"
                                fullWidth
                            />
                            <FoxInputTextField
                                label="UDW"
                                name="emailBcc"
                                fullWidth
                            />
                            <FoxInputTextField
                                label="Odpowiedz do"
                                name="emailReplyTo"
                                fullWidth
                            />
                            <FoxHtmlEditorField
                                label="Email"
                                name="emailContent"
                                required
                            />

                            <PreviewGenerator index={index} />
                        </FormSection>
                    </section>
                    <div className={styles.buttonsContainer}>
                        <FoxButton
                            primary
                            disabled={submitting}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Zapisz
                        </FoxButton>
                    </div>
                </TabStripTab>
            ))}
        </TabStrip>
    );
}

NotificationTemplateEdit.propTypes = propTypes;
NotificationTemplateEdit.defaultProps = defaultProps;

export default compose(
    reduxForm({
        form: 'notifcationTemplateEdit',
        enableReinitialize: true
    }),
    formValues({
        definitions: 'definitions',
        availablePlaceholders: 'availablePlaceholders'
    }),
    memo
)(NotificationTemplateEdit);
