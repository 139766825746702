import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/stylingUtils';

const ListItemWrapper = styled.li`
    display: flex;
    align-items: center;
    font-size: 1em;

    & + & {
        margin-top: ${pxToRem(6)};
    }

    ${({ centerOnMobile }) =>
        centerOnMobile &&
        css`
            @media screen and (max-width: 476px) {
                text-align: center;
            }
        `}
`;

export default ListItemWrapper;
