import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import TimelineDescription from './TimelineItemDescription';
import { formatAsTitleText } from 'utils';

const propTypes = {};

const defaultProps = {};

function TrackingInfo({ trackingInfo }) {
    const { t } = useTranslation();
    return (
        <>
            {trackingInfo?.ipAddress && (
                <TimelineDescription>
                    {`${formatAsTitleText(
                        t('Admin.Invoice.Timeline.Item.IpAddress')
                    )} ${trackingInfo?.ipAddress}`}
                </TimelineDescription>
            )}
            {trackingInfo?.userAgent && (
                <TimelineDescription>
                    {`${formatAsTitleText(
                        t('Admin.Invoice.Timeline.Item.UserBrowser')
                    )} ${trackingInfo.userAgent}`}
                </TimelineDescription>
            )}
        </>
    );
}

TrackingInfo.propTypes = propTypes;
TrackingInfo.defaultProps = defaultProps;

export default memo(TrackingInfo);
