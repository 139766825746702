import React, { memo } from 'react';
import Invoices from './Invoices';
import { useDispatch } from 'react-redux';
import { getForGrid } from './action';

const propTypes = {};

const defaultProps = {};

function InvoicesContainer() {
    const dispatch = useDispatch();
    const onLoadData = dataState => dispatch(getForGrid(dataState));

    return <Invoices onLoadData={onLoadData} />;
}

InvoicesContainer.propTypes = propTypes;
InvoicesContainer.defaultProps = defaultProps;

export default memo(InvoicesContainer);
