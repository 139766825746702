/* eslint-disable react/no-access-state-in-setstate */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
    GridColumnMenuSort,
    GridColumnMenuFilter,
    GridColumnMenuItemGroup,
    GridColumnMenuItem,
    GridColumnMenuItemContent
} from '@progress/kendo-react-grid';
import FoxButton from '../FoxButton';
import plMessages from './pl.json';

const propTypes = {
    columns: PropTypes.any.isRequired,
    onColumnsSubmit: PropTypes.func.isRequired,
    onCloseMenu: PropTypes.func.isRequired
};

const defaultProps = {};

class FoxGridColumnMenu extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            columns: this.props.columns,
            columnsExpanded: false,
            filterExpanded: false
        };

        this.onToggleColumn = this.onToggleColumn.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onFilterExpandChange = this.onFilterExpandChange.bind(this);
    }

    onToggleColumn(id) {
        this.setState({
            columns: this.state.columns.map((column, index) =>
                index === id
                    ? {
                          ...column,
                          props: {
                              ...column.props,
                              hidden: !column.props.hidden
                          }
                      }
                    : column
            )
        });
    }

    onReset(event) {
        event.preventDefault();
        const allColumns = this.props.columns.map(column => {
            return {
                ...column,
                hidden: false
            };
        });

        this.setState({ columns: allColumns }, () => this.onSubmit());
    }

    onSubmit(event) {
        if (event) {
            event.preventDefault();
        }
        this.props.onColumnsSubmit(this.state.columns);
        if (this.props.onCloseMenu) {
            this.props.onCloseMenu();
        }
    }

    onMenuItemClick() {
        const value = !this.state.columnsExpanded;
        this.setState({
            columnsExpanded: value,
            filterExpanded: value ? false : this.state.filterExpanded
        });
    }

    onFilterExpandChange(value) {
        this.setState({
            filterExpanded: value,
            columnsExpanded: value ? false : this.state.columnsExpanded
        });
    }

    render() {
        return (
            <div>
                <GridColumnMenuSort {...this.props} />
                <GridColumnMenuFilter
                    {...this.props}
                    onExpandChange={this.onFilterExpandChange}
                    expanded={this.state.filterExpanded}
                />
                <GridColumnMenuItemGroup>
                    <GridColumnMenuItem
                        title="Columns"
                        iconClass="k-i-columns"
                        onClick={this.onMenuItemClick}
                    />
                    <GridColumnMenuItemContent
                        show={this.state.columnsExpanded}
                    >
                        <div className="k-column-list-wrapper">
                            <form
                                onSubmit={this.onSubmit}
                                onReset={this.onReset}
                            >
                                <div className="k-column-list">
                                    {this.state.columns.map((column, index) => (
                                        <div
                                            key={index}
                                            className="k-column-list-item"
                                        >
                                            <span>
                                                <input
                                                    id={`column-visiblity-show-${index}`}
                                                    className="k-checkbox"
                                                    type="checkbox"
                                                    readOnly
                                                    checked={
                                                        !column.props.hidden
                                                    }
                                                    onClick={() => {
                                                        this.onToggleColumn(
                                                            index
                                                        );
                                                    }}
                                                />
                                                <label
                                                    htmlFor={`column-visiblity-show-${index}`}
                                                    className="k-checkbox-label"
                                                    style={{
                                                        userSelect: 'none'
                                                    }}
                                                >
                                                    {column.props.title}
                                                </label>
                                            </span>
                                        </div>
                                    ))}
                                </div>
                                <div className="k-columnmenu-actions">
                                    <FoxButton type="reset">
                                        {plMessages.grid.reset}
                                    </FoxButton>
                                    <FoxButton primary>
                                        {plMessages.grid.save}
                                    </FoxButton>
                                </div>
                            </form>
                        </div>
                    </GridColumnMenuItemContent>
                </GridColumnMenuItemGroup>
            </div>
        );
    }
}

FoxGridColumnMenu.propTypes = propTypes;
FoxGridColumnMenu.defaultProps = defaultProps;

export default FoxGridColumnMenu;
