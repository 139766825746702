import styled from 'styled-components';

const StyledName = styled.div`
    font-size: 1.05rem;

    @media screen and (max-width: 476px) {
        font-size: 1.15rem;
    }
`;

export default StyledName;
