import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './CloseButton.module.scss';
import { useInterval } from 'utils/customHooks';
import CircularProgressBar from './CircularProgressBar';

const propTypes = {
    onClose: PropTypes.func.isRequired,
    className: PropTypes.string,
    closeTime: PropTypes.number.isRequired,
    toastIsTouched: PropTypes.bool,
    counterDelay: PropTypes.number
};

const defaultProps = {
    className: '',
    toastIsTouched: false,
    counterDelay: 0
};

const CloseButton = ({
    onClose,
    className,
    closeTime,
    toastIsTouched,
    counterDelay
}) => {
    const [timeLeft, setTimeLeft] = useState(closeTime);
    const [currentDelay, setCurrentDelay] = useState(counterDelay);
    const buttonClassName = cx(styles.button_container, className);
    const timeTick = 125;

    useInterval(
        () => {
            if (currentDelay <= 0) {
                !toastIsTouched &&
                    setTimeLeft(prevTimeLeft => prevTimeLeft - timeTick);
                timeLeft <= 0 && setTimeLeft(0);
            } else {
                setCurrentDelay(prevDelay => prevDelay - timeTick);
            }
        },
        timeLeft >= 0 ? timeTick : null
    );

    useEffect(() => {
        timeLeft <= 0 && onClose();
    }, [timeLeft, onClose]);

    useEffect(() => {
        setTimeLeft(closeTime);
    }, [toastIsTouched, closeTime]);

    const getCurrentProgress = () =>
        Math.round(((closeTime - timeLeft) / closeTime) * 100, 2);

    return (
        <div className={buttonClassName} onClick={onClose}>
            <CircularProgressBar
                className={styles.progress_bar}
                progress={getCurrentProgress()}
            />
            <div className={styles.cross} />
        </div>
    );
};

CloseButton.propTypes = propTypes;
CloseButton.defaultProps = defaultProps;

export default CloseButton;
