import React, { memo } from 'react';
import PropTypes from 'prop-types';
import withColors from 'common/hoc/withColors';
import sharedPropTypes from 'utils/sharedPropTypes';
import Header from 'common/Header';

import StyledIcon from './StyledIcon';
import StyledWrapper from './StyledWrapper';

const propTypes = {
    children: PropTypes.node.isRequired,
    icon: PropTypes.oneOfType(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    iconType: sharedPropTypes.iconType,
    title: PropTypes.string,
    // NOTE Vorbert -> ogarnąc lepszą metodę dla tego
    moreVerticalOffset: PropTypes.bool
};

const defaultProps = {
    icon: undefined,
    iconType: undefined,
    title: undefined
};

const Section = ({
    children,
    icon,
    iconType,
    title,
    moreVerticalOffset,
    ...others
}) => {
    return (
        <StyledWrapper
            {...others}
            withIcon={!!icon}
            moreVerticalOffset={moreVerticalOffset}
        >
            {title && <Header>{title}</Header>}
            {children}
            {icon && <StyledIcon icon={icon} iconType={iconType} />}
        </StyledWrapper>
    );
};

Section.propTypes = propTypes;
Section.defaultProps = defaultProps;

export default memo(withColors(Section));
