import styled from 'styled-components';
import { pxToRem } from 'utils/stylingUtils';

const StyledWrapper = styled.div`
    @media screen and (min-width: 476px) {
        margin-left: ${pxToRem(12)};
    }
`;

export default StyledWrapper;
