const initialState = {
  results: {
    items: [],
    totalCount: 0
  },
  columnsInfo: [],
  dataState: {
    skip: 0,
    take: 20,
    sort: [],
    group: []
  }
};

export default initialState;
